import { useState } from "react";
import styles from "../../BusinessPlanningTool.module.css";
import { Flex } from "antd";
import Button from "../../../../components/Shared/Button/Button";
import FormInput from "../../../../components/Shared/FormInput/FormInput";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	setProgress,
	setBusinessToolData,
} from "../../../../redux/slices/businessSlice";
import HoldingComputer from "../../../../assets/BusinessPlanningTool/people_images/holding_computer.png";
import TitleText from "../../../../components/Shared/Text/TitleText.jsx";
import BodyText from "../../../../components/Shared/Text/BodyText.jsx";
import removeNonNumericalChars from "../../../../utils/removeNonNumericalChars.js";

export const PersonalFinanceForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [monthly, setMonthly] = useState(null);
	const [income, setIncome] = useState(null);
	const [assets, setAssets] = useState(null);
	const [debts, setDebts] = useState(null);
	const [showSummary, setShowSummary] = useState(false);
	const businessToolData = useSelector(
		(state) => state.business.businessToolData,
	);

	const handleSubmit = () => {
		// TODO SEND TO THE API
		setShowSummary(true);
	};

	const handleAssets = () => {
		const number = parseInt(income, 10) + parseInt(assets, 10);
		return number.toLocaleString();
	};

	const handleLiabilities = () => {
		const number = parseInt(monthly, 10) + parseInt(debts, 10);
		return number.toLocaleString();
	};

	const handleNetWorth = () => {
		const assetsIncome = parseInt(income, 10) + parseInt(assets, 10);
		const costsDebt = parseInt(monthly, 10) + parseInt(debts, 10);
		return assetsIncome - costsDebt;
	};

	const handleNetWorthFormat = () => {
		const assetsIncome = parseInt(income, 10) + parseInt(assets, 10);
		const costsDebt = parseInt(monthly, 10) + parseInt(debts, 10);
		const total = assetsIncome - costsDebt;
		return total.toLocaleString();
	};

	const handleData = () => {
		const personalData = {
			personalFinance: {
				monthly: monthly,
				income: income,
				assets: assets,
				debts: debts,
			},
		};
		dispatch(setBusinessToolData({ ...businessToolData, ...personalData }));
	};

	const handleContinue = () => {
		handleData();
		setShowSummary(false);
		navigate("/business");
		dispatch(setProgress(2));
	};

	return (
		<Flex vertical style={{ width: "100%" }}>
			<Flex className={{ textAlign: "left", paddingBottom: 40 }}>
				<TitleText
					text={
						!showSummary
							? "Personal Finance"
							: "Congrats! Here is your balance sheet."
					}
				/>
			</Flex>
			<hr className={styles.sectionLine} />
			<Flex style={{ height: 220 }} horizontal align="center" justify="center">
				<img width={58} height={152} src={HoldingComputer} />
				<BodyText text="Understand your personal financial situation." />
			</Flex>

			{!showSummary ? (
				<>
					<Flex vertical align="flex-start">
						<FormInput
							isRequired={true}
							name="monthly-cost"
							placeholder="Here you will enter your estimate of total expenses per month. This can include groceries, rent, daycare costs, etc. Please enter a numerical value (ie. 10,000). Do not include commas or other punctuation."
							value={monthly}
							onChange={(e) =>
								setMonthly(removeNonNumericalChars(e.target.value))
							}
							hasLabel
							formLabel="What are your total monthly costs?"
							isMultiline={true}
						/>
					</Flex>

					<div className={styles.formSpacing} />

					<Flex vertical align="flex-start">
						<FormInput
							isRequired={true}
							name="monthly-cost"
							placeholder="Here you will enter your estimate of total income per month. This can include money from child support, hourly and salaried wages, retirement income, etc. Please enter a numerical value (ie. 10,000). Do not include commas or other punctuation."
							value={income}
							onChange={(e) =>
								setIncome(removeNonNumericalChars(e.target.value))
							}
							hasLabel
							formLabel="What is your total monthly income?"
							isMultiline={true}
						/>
					</Flex>

					<div className={styles.formSpacing} />

					<Flex vertical align="flex-start">
						<FormInput
							isRequired={true}
							name="assets"
							placeholder=" Here you will enter your monetary estimate of total assets owned. This can include stocks, 401K/retirement, home value, car value, etc. Please enter a numerical value (ie. 10,000). Do not include commas or other punctuation."
							value={assets}
							onChange={(e) =>
								setAssets(removeNonNumericalChars(e.target.value))
							}
							hasLabel
							formLabel="How much do you own in assets?"
							isMultiline={true}
						/>
					</Flex>

					<div className={styles.formSpacing} />

					<Flex vertical align="flex-start">
						<FormInput
							isRequired={true}
							name="debts"
							placeholder=" Here you will enter the total amount of money you owe. This can include school loans, personal loans, mortgage, credit card debt, etc. Please enter a numerical value (ie. 10,000). Do not include commas or other punctuation."
							value={debts}
							onChange={(e) =>
								setDebts(removeNonNumericalChars(e.target.value))
							}
							hasLabel
							formLabel="How much do you owe in debt?"
							isMultiline={true}
						/>
					</Flex>

					<div className={styles.formSpacing} />

					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleSubmit} />
					</Flex>
				</>
			) : (
				<>
					<Flex
						horizontal
						align="center"
						justify="space-between"
						className={styles.summaryCard}
					>
						<BodyText text="Total Assets" />
						<BodyText text={`$ ${handleAssets()}`} />
					</Flex>

					<div className={styles.formSpacing} />

					<Flex
						horizontal
						align="center"
						justify="space-between"
						className={styles.summaryCard}
					>
						<BodyText text="Total Liabilities" />
						<BodyText text={`$ ${handleLiabilities()}`} />
					</Flex>

					<div className={styles.formSpacing} />

					<Flex
						horizontal
						align="center"
						justify="space-between"
						className={styles.summaryCard}
					>
						<p
							className={
								handleNetWorth() > 0
									? styles.summaryLabel
									: styles.redSummaryLabel
							}
						>
							Net Worth
						</p>
						<p
							className={
								handleNetWorth() > 0
									? styles.summaryLabel
									: styles.redSummaryLabel
							}
						>
							${handleNetWorthFormat()}
						</p>
					</Flex>

					<div className={styles.formSpacing} />

					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleContinue} />
					</Flex>
				</>
			)}
		</Flex>
	);
};

import { logEvent, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
	authDomain: "centro-web-app.firebaseapp.com",
	projectId: "centro-web-app",
	storageBucket: "centro-web-app.appspot.com",
	messagingSenderId: import.meta.env.VITE_FIREBASE_SENDER_ID,
	appId: import.meta.env.VITE_FIREBASE_APP_ID,
	measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const logScreen = (screenName) => {
	logEvent(analytics, "screen_view", { screen: screenName });
};

export const logOut = () => {
	logEvent(analytics, "log_out");
};

export const login = (loginMethod) => {
	logEvent(analytics, "login", { method: loginMethod });
};

export const signUp = (loginMethod) => {
	logEvent(analytics, "sign_up", { method: loginMethod });
};

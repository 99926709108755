export const industryList = [
	"Agriculture",
	"Dining and Entertainment",
	"Domestic Services",
	"Products",
	"Health and Fitness",
	"Technical Services",
	"Professional Services",
	"Other",
];

export const agList = [
	"Dairy",
	"Fruits, Vegetables, and Grains",
	"Meat and Poultry",
	"Other",
];

export const diningEntList = [
	"Bar/Club",
	"Catering",
	"Performing Arts",
	"Restaurant",
	"Tourism",
	"Other",
];

export const domesticList = [
	"Childcare",
	"Cleaning",
	"Gardening",
	"Maintenance",
	"Other",
];

export const productsList = [
	"Body and Hygiene",
	"Clothing and Footwear",
	"Crafts and Decorating",
	"Food and Groceries",
	"Furniture",
	"General Products",
	"Household Goods",
	"Jewelry",
	"Sporting Goods",
	"Technology",
	"Textiles",
	"Other",
];

export const healthFitnessList = [
	"General Health Services",
	"Medical Practitioner",
	"Personal Fitness",
	"Pharmaceuticals",
	"Other",
];

export const technicalServicesList = [
	"Automobile Maintenance",
	"Carpentry",
	"Construction",
	"Electrical",
	"Garment Construction and Repair",
	"Hair and Beauty",
	"IT",
	"Transportation",
	"Other",
];

export const professionalList = [
	"Accounting",
	"Architecture and Design",
	"Financial",
	"Insurance",
	"Legal",
	"Other",
];

export const otherList = ["Other"];

export const customerActivityList = [
	"I don’t think this is important",
	"Gardening",
	"Volunteering",
	"Extreme Sports",
	"Camping and Hiking",
	"Cooking",
	"Reading",
	"Music",
	"Creative Writing",
	"TV and Video games",
	"Art",
	"Social Networking",
	"Outdoor Sports and Recreation",
	"Cards and Board Games",
	"Photography",
	"Astronomy",
	"Collecting",
	"Exercise and Fitness",
];

export const customerWorkList = [
	"I don’t think this is important",
	"Homemaker",
	"Lawyer",
	"Electrician",
	"Plumber",
	"Teacher",
	"Salesperson",
	"Construction",
	"Engineer",
	"Parent",
	"Student",
	"Cashier",
	"Waiter/Waitress",
	"Office Worker",
	"Nurse",
	"Doctor",
	"Customer Service Rep",
	"Laborer",
	"Manager",
	"Cleaning Services",
];

export const customerEdList = [
	"I don’t think this is important",
	"Elementary / Middle School",
	"Some High School",
	"High School",
	"Some College",
	"College",
	"Trade School",
	"Graduate School",
	"Professional Certifications",
	"None",
];

export const competitorsList = [
	"A lot (more than 20) - High",
	"Quite a few (5 to 20) - Medium",
	"Not many (less than 5) - Low",
];

export const volatileList = [
	"Yes, it changes a lot",
	"Some volatility",
	"No, nothing changes",
];

export const regulatedList = [
	"A lot of regulations",
	"Some regulations",
	"Hardly any regulations",
	"No regulations",
];

export const marketAgeList = [
	"New market (less than 5 years)",
	"Recent market (5 to 10 years)",
	"Established market (10 to 50 years)",
	"Traditional market (more than 50 years)",
];

export const growingList = [
	"Yes, very quickly!",
	"Yes, but slowly.",
	"It’s not changing.",
	"It’s shrinking.",
];

import { useState, useEffect } from "react";
// import Button from "@mui/material/Button";
import { Row, Col, Flex } from "antd";
// import ButtonGroup from "@mui/material/ButtonGroup";
import styles from "./Capital.module.css";
import NavDrawer from "../../components/Shared/NavDrawer/NavDrawer";
import { logScreen } from "../../utils/analytics";
import CapitalOptions from "./CapitalOptions";
import HeadlineText from "../../components/Shared/Text/HeadlineText.jsx";

const CapitalPage = () => {
  const [state, setState] = useState("options");

  useEffect(() => {
    logScreen("CAPITAL");
  }, []);

  const states = {
    options: <CapitalOptions />,
  };

  return (
    <>
      <NavDrawer />
      {/*
        Note: due to delivery constraints this is pushed out
      to V2. -sic
      <ChatButton />
      */}
      <div style={{ marginLeft: "16rem", position: "relative", zIndex: 1 }}>
        <Row style={{ padding: "44px 32px 32px 56px", margin: "0" }}>
          <Col span={24} style={{ padding: "0", margin: "0" }}>
            <Flex
              vertical
              style={{ paddingLeft: 40 }}
              justify="flex-start"
              align="center"
              gap={24}
            >
              <Flex
                style={{ width: "100%" }}
                className={styles.titlePadding}
                justify="flex-start"
                align="center"
              >
                <HeadlineText isHeavy text="Capital" />
              </Flex>

              {/* // TODO once we add more features comment this back in. -sic         
              <ButtonGroup
                disabled={false}
                orientation="horizontal"
                size="large"
                variant="outlined"
                sx={{
                  p: 2,
                  padding: "0",
                  width: "100%",
                  display: "flex",
                }}
              >
                <Button
                  className={styles.SectionButton}
                  onClick={() => {
                    setState("options");
                  }}
                >
                  Capital Options
                </Button>
              </ButtonGroup>
              */}
              {states[state]}
            </Flex>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CapitalPage;

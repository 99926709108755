import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    hasSeenTutorial: false,
  },
  reducers: {
    setUserObject: (state, action) => {
      state.user = action.payload;
    },
    setSeenTutorial: (state, action) => {
      state.hasSeenTutorial = action.payload;
    },
  },
});

export const { setUserObject, setSeenTutorial } = userSlice.actions;

export default userSlice.reducer;

import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NavDrawer from "../../components/Shared/NavDrawer/NavDrawer.jsx";
import JourneyMap from "../../components/Shared/JourneyMap/JourneyMap.jsx";
import Button from "../../components/Shared/Button/Button";
// import Resources from "../../components/Shared/Resources/Resources.jsx";
// import CoursesSection from "../../components/Shared/CoursesSection/CoursesSection.jsx";
// import TodoListSection from "../../components/Shared/ToDoListSection/ToDoListSection.jsx";
import "../../components/chat/ChatButton.css";
import { Flex, Row, Col, Divider } from "antd";
// import { useSelector } from "react-redux";
import { logScreen } from "../../utils/analytics";
import HeadlineText from "../../components/Shared/Text/HeadlineText.jsx";
import SubheaderText from "../../components/Shared/Text/SubheaderText.jsx";
import PersonImg from "../../assets/Group_2.png";
import PersonImg2 from "../../assets/pavan_6416.png";
import PersonImg3 from "../../assets/pavan_1663.png";
import PersonImg4 from "../../assets/pavan_6417.png";
import PersonImg5 from "../../assets/pavan_6417_1.png";
//import CentroLogo from "../../assets/Centro_Logo_Horizontal.svg?react";
import CentroLogo from "../../assets/Centro_Logos.png";
import { setSeenTutorial } from "../../redux/slices/userSlice";

/* The Modal (background) */
const modal = {
  display: "block" /* Hidden by default */,
  position: "fixed" /* Stay in place */,
  zIndex: 1 /* Sit on top */,
  left: 20,
  top: 0,
  width: "100%" /* Full width */,
  height: "100%" /* Full height */,
  overflow: "auto" /* Enable scroll if needed */,
  backgroundColor: "white" /* rgb(0,0,0),Fallback color */,
  /*  background-color: rgba(0,0,0,0.4) Black w/ opacity */
};

const modal_hidden = {
  display: "none" /* Hidden by default */,
  position: "fixed" /* Stay in place */,
  zIndex: 1 /* Sit on top */,
  left: 20,
  top: 0,
  width: "100%" /* Full width */,
  height: "100%" /* Full height */,
  overflow: "auto" /* Enable scroll if needed */,
  backgroundColor: "white" /* rgb(0,0,0),Fallback color */,
  /*  background-color: rgba(0,0,0,0.4) Black w/ opacity */
};

/* Modal Content/Box */
const modal_content = {
  backgroundColor: "#F7F9EB",
  margin: "10% auto" /* 15% from the top and centered */,
  padding: "40px",
  border: "2px solid #888",
  width: "60%" /* Could be more or less, depending on screen size */,
};

/* The Close Button */
const modal_close = {
  color: "#fff",
  float: "right",
  fontSize: "18px",
  fontWeight: "bold",
  height: "26px",
  width: "26px",
  backgroundColor: "#bbb",
  borderRadius: "50%",
  display: "inline-block",
  verticalAlign: "middle",
  cursor: "pointer",
};

/* The Close Button */
const imageContainer = {
  // color: "#fff",
  //float: "right",
  // fontSize: "18px",
  // fontWeight: "bold",
  height: "250px",
  width: "250px",
  backgroundColor: "#fff",
  borderRadius: "60%",
  //display: "inline-block",
  align: "top",
  // verticalAlign: "middle"
};

const modal_heading = {
  fontSize: "40px",
  // fontWeight: "bold",
};

const modal_subtext = {
  fontSize: "20px",
  // fontWeight: "bold",
};

const dots_default = {
  fontSize: "36px",
  fontWeight: "bold",
};

const dots_selected = {
  color: "black",
};

const dots_unselected = {
  color: "gray",
  cursor: "pointer",
};

const section_revealed = {
  display: "block",
};

const section_hidden = {
  display: "none",
};

const centro_logo = {
  width: 20 /* Full width */,
  height: 10,
  display: "inline-block",
  position: "relative",
  right: "8%",
  /*   svg : {
     width: "16px",
     height: "16px",
   }
   */
};

const chevron_style = {
  fontSize: 72,
  color: "#C2C9D1",
  cursor: "pointer",
};

/*
.close:hover,
.close:focus {
  color: "black",
  text-decoration: "none",
  cursor: "pointer"
};
*/
/*
const [modalState, setModalState] = useState("block");

  const handleCloseModal = (name) => {
dispatch(setModalState("none"));
  };
*/

//const modalIndex = 0;

const modalValues = [
  {
    heading: "Welcome to Centro CEO",
    subtext: "Let us help you turn your entreprenurial vision into reality.",
    imgUrl: `${PersonImg}`,
  },
  {
    heading: "Create a business plan",
    subtext:
      "Use our business planning tool to jump start your entrepreneurial journey and fund your vision.",
    imgUrl: `${PersonImg2}`,
  },
  {
    heading: "Expand your industry knowledge",
    subtext:
      "Learn from other entrepreneurs within the community and gain valuable first-hand knowledge on how to navigate challenges and achieve success in your business endeavors.",
    imgUrl: `${PersonImg3}`,
  },
  {
    heading: "Browse our endless resources",
    subtext:
      "View our curated list of resources from leading industry experts to gain insights, strategies, and tools that will accelerate your entrepreneurial journey.",
    imgUrl: `${PersonImg4}`,
  },
  {
    heading: "Get Started",
    subtext:
      "Begin filling out your business plan and view which loans are available to start your business today.",
    imgUrl: `${PersonImg5}`,
  },
];

const LandingPage = () => {
  const [modalIndex, setModalIndex] = useState(0);
  const [modalClosed, setModalClosed] = useState(0);
  const [tutorialEnd, setTutorialEnd] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const name = null;
  //   useSelector((state) => state.user.user.providerData.displayName) ?? null;
  const usersName = name !== null ? `, ${name}` : "";
  const tutorialSeen = useSelector((state) => state.user.hasSeenTutorial);

  useEffect(() => {
    logScreen("HOME");
  }, []);

  useEffect(() => {
    const showTutorial = localStorage.getItem("hasSeenTutorial");
    if (showTutorial === "true") {
      dispatch(setSeenTutorial(true));
    }
  }, []);

  const handleToggleModal = async (num_index, operator) => {
    var selectedIndex;

    if (operator === "+" && num_index >= 4) {
      selectedIndex = 4;
    } else if (operator === "+") {
      selectedIndex = num_index;
      setTutorialEnd(0);
    }

    if (operator === "-" && num_index <= 0) {
      selectedIndex = 0;
    } else if (operator === "-") {
      selectedIndex = num_index;
      setTutorialEnd(0);
    }

    if (selectedIndex === 4) {
      setTutorialEnd(1);
    }

    setModalIndex(selectedIndex);
  };

  return (
    <>
      <NavDrawer />
      {/*
      Note: due to delivery constraints this is pushed out
      to V2. -sic
      <ChatButton />
      */}
      <div style={{ marginLeft: "140px", position: "relative", zIndex: 1 }}>
        <Row style={{ padding: "44px 32px 32px 56px", margin: "0" }}>
          <Col
            span={16}
            style={{ paddingRight: "28px", paddingLeft: "0px", margin: "0" }}
          >
            <Flex vertical justify="flex-start" align="center" gap={24}>
              <Flex
                style={{ width: "100%" }}
                justify="flex-start"
                align="center"
              >
                <HeadlineText isHeavy text={`Welcome Back${usersName}`} />
              </Flex>

              <Flex
                gap={16}
                vertical
                style={{ width: "100%", textAlign: "left" }}
              >
                <SubheaderText isHeavy text="Centro CEO Journey Map" />
                <JourneyMap />
              </Flex>

              {/*      
              <Divider
                style={{ margin: "0", padding: "0", background: "#9AA0A6" }}
              />
              */}

              {/*<CoursesSection />*/}

              <Divider
                style={{ margin: "0", padding: "0", background: "#9AA0A6" }}
              />

              {/*            <Flex
                gap={16}
                vertical
                style={{ width: "100%", textAlign: "left" }}
              >
                <p className={styles.subsection}>Resources</p>
                <Resources
                  title="Market Analysis"
                  subtext={"www.link.com"}
                  number={1}
                />
                <Resources
                  title="Operations"
                  subtext={"www.link.com"}
                  number={2}
                />
                <Resources
                  title="Marketing Strategies"
                  subtext={"www.link.com"}
                  number={3}
                />
              </Flex>*/}
            </Flex>
          </Col>

          <Col
            span={8}
            style={{
              paddingLeft: "28px",
              paddingRight: "0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Flex vertical justify="flex-start" align="center" gap={24}>
              {/*{<TodoListSection />}*/}

              <Divider
                style={{ margin: "0", padding: "0", background: "#9AA0A6" }}
              />

              {/*
              // Note: due to delivery constraints
              this is going to be pushed out to V2. -sic
              <Ad width={326} height={150} />
              */}
            </Flex>
          </Col>
        </Row>
      </div>
      {/*
      <!-- Trigger/Open The Modal -->
      */}
      {/* 
<button id="myBtn">Open Modal</button>

<!-- The Modal -->
*/}
      <>
        {tutorialSeen ? null : (
          <div
            id="myModal"
            className="modal"
            style={modalClosed === 0 ? modal : modal_hidden}
          >
            {/*
  <!-- Modal content -->
  */}
            <div className="modal-content" style={modal_content}>
              <span
                className="close"
                style={modal_close}
                onClick={() => {
                  setModalClosed(1);
                  dispatch(setSeenTutorial(true));
                  localStorage.setItem("hasSeenTutorial", "true");
                }}
              >
                &times;
              </span>
              <div style={centro_logo}>
                <img src={CentroLogo} />
              </div>

              <br />
              <br />
              <Flex
                horizontal
                style={{
                  width: "100%",
                  textAlign: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={chevron_style}
                  onClick={() => handleToggleModal(modalIndex - 1, "-")}
                >
                  &lsaquo;
                </p>
                <div style={imageContainer}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img src={modalValues[modalIndex].imgUrl} />
                </div>
                <p
                  style={chevron_style}
                  onClick={() => handleToggleModal(modalIndex + 1, "+")}
                >
                  &rsaquo;
                </p>
              </Flex>
              <p style={modal_heading}>{modalValues[modalIndex].heading}</p>

              <p style={modal_subtext}>{modalValues[modalIndex].subtext}</p>
              <div
                style={tutorialEnd === 0 ? section_revealed : section_hidden}
              >
                <p style={dots_default}>
                  <span
                    style={modalIndex === 0 ? dots_selected : dots_unselected}
                    onClick={() => handleToggleModal(0, "+")}
                  >
                    &#x2022;{" "}
                  </span>
                  <span
                    style={modalIndex === 1 ? dots_selected : dots_unselected}
                    onClick={() => handleToggleModal(1, "+")}
                  >
                    &#x2022;{" "}
                  </span>
                  <span
                    style={modalIndex === 2 ? dots_selected : dots_unselected}
                    onClick={() => handleToggleModal(2, "+")}
                  >
                    &#x2022;{" "}
                  </span>
                  <span
                    style={modalIndex === 3 ? dots_selected : dots_unselected}
                    onClick={() => handleToggleModal(3, "+")}
                  >
                    &#x2022;{" "}
                  </span>
                  <span
                    style={modalIndex === 4 ? dots_selected : dots_unselected}
                    onClick={() => handleToggleModal(4, "+")}
                  >
                    &#x2022;{" "}
                  </span>
                </p>
              </div>
              <div
                style={tutorialEnd === 1 ? section_revealed : section_hidden}
              >
                <Button
                  onClick={() => {
                    navigate("/business");
                    dispatch(setSeenTutorial(true));
                    localStorage.setItem("hasSeenTutorial", "true");
                  }}
                  content="Get Started"
                />
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default LandingPage;

import { Flex, Divider } from "antd";
import styles from "./ProfilePage.module.css";
import FormInput from "../../components/Shared/FormInput/FormInput";
import Button from "../../components/Shared/Button/Button";
import { languageList } from "../../utils/profileData";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Check } from "@mui/icons-material";

// TODO reset password is a Firebase function -sic

export const AccountInformation = ({
  profileInfo,
  isEditingAccount,
  resetPassword,
  editAccount,
  handleSetEmail,
  handleSetPhone,
  handleSetDob,
  handleSetLanguage,
  handleSaveAccount,
}) => {
  return (
    <Flex style={{ width: "100%" }} vertical justify="center" align="center">
      <Flex
        vertical
        justify="center"
        align="center"
        className={styles.ProfileCard}
      >
        <Flex
          style={{ width: "100%" }}
          justify="space-between"
          align="center"
          horizontal
          gap={24}
        >
          <p className={styles.profileTitle}>Account Info</p>
          <Button
            content={isEditingAccount ? "Cancel" : "Edit"}
            onClick={editAccount}
          />
        </Flex>
        <Flex
          vertical
          style={{ width: "30%", marginBottom: isEditingAccount ? 30 : 0 }}
        >
          <p className={styles.profileCategory}>Email address</p>
          {isEditingAccount ? (
            <FormInput
              name="Email"
              placeholder="Email"
              value={profileInfo.email}
              onChange={handleSetEmail}
            />
          ) : (
            <p className={styles.profileData}>{profileInfo.email}</p>
          )}
        </Flex>
        <Divider style={{ margin: "0", padding: "0", background: "#9AA0A6" }} />
        <Flex
          vertical
          style={{ width: "30%", marginBottom: isEditingAccount ? 30 : 0 }}
        >
          <p className={styles.profileCategory}>Phone number</p>
          {isEditingAccount ? (
            <FormInput
              name="Phone number"
              placeholder="Phone number"
              value={profileInfo.phone}
              onChange={handleSetPhone}
            />
          ) : (
            <p className={styles.profileData}>{profileInfo.phone}</p>
          )}
        </Flex>
        <Divider style={{ margin: "0", padding: "0", background: "#9AA0A6" }} />
        <Flex
          vertical
          style={{ width: "30%", marginBottom: isEditingAccount ? 30 : 0 }}
        >
          <p className={styles.profileCategory}>Birthdate</p>
          {isEditingAccount ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className={styles.inputWrapper}
                value={dayjs("2024-06-01")}
                onChange={handleSetDob}
                sx={{
                  borderRadius: 2,
                  ".Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#05B452",
                    borderWidth: 1,
                  },
                }}
                slotProps={{
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#05B452",
                      },
                      ":hover": {
                        backgroundColor: "#EEFFF4",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          ) : (
            <p className={styles.profileData}>
              {dayjs(profileInfo.dob).format("l")}
            </p>
          )}
        </Flex>
        <Divider style={{ margin: "0", padding: "0", background: "#9AA0A6" }} />
        <Flex
          vertical
          style={{ width: "30%", marginBottom: isEditingAccount ? 30 : 0 }}
        >
          <p className={styles.profileCategory}>Language preferences</p>
          {isEditingAccount ? (
            <Box sx={{ minWidth: 300 }}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: 300,
                  borderRadius: 8,
                  "& label.Mui-focused": {
                    color: "#00000080",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00000080",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B4FED4",
                    },
                  },
                }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={profileInfo.language}
                  onChange={handleSetLanguage}
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  {languageList.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        minWidth: 300,
                        "&:hover": {
                          backgroundColor: "#EEFFF4",
                        },
                      }}
                    >
                      <Flex horizontal align="center" justify="space-between">
                        {<p className={styles.appFormLabel}>{option.label}</p>}
                        <div style={{ width: 180 }} />
                        {option.label === profileInfo.language && (
                          <Check fontSize="60" sx={{ color: "#05B452" }} />
                        )}
                      </Flex>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <p className={styles.profileData}>{profileInfo.language}</p>
          )}
        </Flex>
        <Divider style={{ margin: "0", padding: "0", background: "#9AA0A6" }} />
        <Flex
          style={{ width: "100%" }}
          justify="space-between"
          align="center"
          horizontal
          gap={24}
        >
          <p className={styles.profileCategory}>Password</p>
          <Button content="Reset password" onClick={resetPassword} />
        </Flex>
        <Flex style={{ marginTop: 40 }}>
          {isEditingAccount && (
            <Button content="Save changes" onClick={handleSaveAccount} />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

import Coaching from "../components/assets/images/program images/Coaching.png";
import EntrepreneurshipEducation from "../components/assets/images/program images/EntrepreneurshipEducation.png";
import FinancialLiteracyTraining from "../components/assets/images/program images/FinancialLiteracyTraining.png";
import LeadershipDevelopment from "../components/assets/images/program images/LeadershipDevelopment.png";

export const programData = [
  {
    tag: "Tag 1",
    title: "Entrepreneurship Education",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci justo, aliquet et ligula non, fringilla volutpat nunc.",
    total: "6 Courses",
    button: "program",
    image: EntrepreneurshipEducation,
  },
  {
    tag: "Tag 2",
    title: "Leadership Development",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci justo, aliquet et ligula non, fringilla volutpat nunc.",
    total: "10 Courses",
    button: "program",
    image: LeadershipDevelopment,
  },
  {
    tag: "Tag 3",
    title: "Financial Literacy Training",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci justo, aliquet et ligula non, fringilla volutpat nunc.",
    total: "4 Courses",
    button: "program",
    image: FinancialLiteracyTraining,
  },
  {
    tag: "Tag 4",
    title: "1-on-1 Coaching",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci justo, aliquet et ligula non, fringilla volutpat nunc.",
    total: "4 Courses",
    button: "program",
    image: Coaching,
  },
];

import { useEffect } from "react";
import { Row, Col, Flex } from "antd";
import styles from "./ContactPage.module.css";
import NavDrawer from "../../components/Shared/NavDrawer/NavDrawer";
import linkedin from "../../assets/linkedin.svg";
import instagram from "../../assets/instagram.svg";
import facebook from "../../assets/facebook.svg";
import { logScreen } from "../../utils/analytics";

const TermsServicePage = () => {
  useEffect(() => {
    logScreen("TOS");
  }, []);

  return (
    <>
      <NavDrawer />
      <div style={{ marginLeft: "16rem", position: "relative", zIndex: 1 }}>
        <Row style={{ padding: "44px 32px 32px 56px", margin: "0" }}>
          <Col span={24} style={{ padding: "0", margin: "0" }}>
            <Flex vertical justify="flex-start" align="center" gap={24}>
              <Flex style={{ width: "100%" }} vertical>
                <p className={styles.title}>Terms of Service</p>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TermsServicePage;

import { useState } from "react";
import styles from "../../BusinessPlanningTool.module.css";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import {
	marketingChannels,
	customerMarketingChannels,
} from "../../../../utils/marketingChannels";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import Button from "../../../../components/Shared/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
	setProgress,
	setBusinessToolData,
} from "../../../../redux/slices/businessSlice";
import ThreePeople from "../../../../assets/BusinessPlanningTool/people_images/3_people.png";
import TitleText from "../../../../components/Shared/Text/TitleText.jsx";
import BodyText from "../../../../components/Shared/Text/BodyText.jsx";
import SubheaderText from "../../../../components/Shared/Text/SubheaderText.jsx";

export const MarketingStrategyForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showSummary, setShowSummary] = useState(false);
	const [channels, setChannels] = useState([]);
	const [customer, setCustomer] = useState([]);
	const [learnMore, setLearnMore] = useState([]);
	const [stayInTouch, setStayInTouch] = useState([]);
	const [buy, setBuy] = useState([]);
	const businessToolData = useSelector(
		(state) => state.business.businessToolData,
	);

	const GreenCheckbox = withStyles({
		root: {
			color: "#C2C9D1",
			"&$checked": {
				color: "#05B452",
			},
		},
		checked: {},
	})((props) => <Checkbox color="default" {...props} />);

	const handleMarketCheckBox = (event) => {
		setChannels([...channels, event.target.name]);
	};

	const handleCustomerCheckBox = (event) => {
		setCustomer([...customer, event.target.name]);
	};

	const handleLearnMoreCheckBox = (event) => {
		setLearnMore([...learnMore, event.target.name]);
	};

	const handleStayInTouchCheckBox = (event) => {
		setStayInTouch([...stayInTouch, event.target.name]);
	};

	const handleBuyCheckBox = (event) => {
		setBuy([...buy, event.target.name]);
	};

	const handleSave = () => {
		// TODO SEND TO THE API
		setShowSummary(true);
	};

	const handleData = () => {
		const marketingStrategyData = {
			marketingStrategy: {
				channels: channels,
				customer: customer,
				learnMore: learnMore,
				stayInTouch: stayInTouch,
				buy: buy,
			},
		};
		dispatch(
			setBusinessToolData({ ...businessToolData, ...marketingStrategyData }),
		);
	};

	const handleContinue = () => {
		handleData();
		dispatch(setProgress(5));
		setShowSummary(false);
		navigate("/business");
	};

	return (
		<Flex vertical style={{ width: "100%" }}>
			<Flex className={{ textAlign: "left", paddingBottom: 40 }}>
				<TitleText
					text={
						!showSummary
							? "Marketing Strategy"
							: "Here are your distribution and promotion channels:"
					}
				/>
			</Flex>
			<hr className={styles.sectionLine} />
			<Flex style={{ height: 220 }} horizontal align="center" justify="center">
				<img
					width={144}
					height={127}
					src={ThreePeople}
					style={{ paddingRight: 20 }}
				/>
				<BodyText
					text="Describe how your business promotes and distributes its
					products/services."
				/>
			</Flex>

			{!showSummary ? (
				<>
					<Flex vertical>
						<Flex justify="flex-start" align="flex-start" vertical>
							<Flex vertical>
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="What channels does your business use to promote and distribute its
									products/services?"
									/>
								</Flex>
								<FormGroup column>
									{marketingChannels.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleMarketCheckBox}
													checked={channels.includes(o)}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>

								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="Through which channels do most customers first hear about ABC
									Inc.?"
									/>
								</Flex>
								<FormGroup column>
									{customerMarketingChannels.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleCustomerCheckBox}
													checked={customer.includes(o)}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>

								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="Through which channels do most customers use to learn more
									about your business?"
									/>
								</Flex>
								<FormGroup column>
									{customerMarketingChannels.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleLearnMoreCheckBox}
													checked={learnMore.includes(o)}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>

								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="Through which channels do most customers stay in touch with
									your business?"
									/>
								</Flex>
								<FormGroup column>
									{customerMarketingChannels.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleStayInTouchCheckBox}
													checked={stayInTouch.includes(o)}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>

								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="Which channels can customers use to buy products/services from
									your business?"
									/>
								</Flex>
								<FormGroup column>
									{customerMarketingChannels.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleBuyCheckBox}
													checked={buy.includes(o)}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>

								<div className={styles.formSpacing} />

								<Flex justify="flex-start" horizontal>
									<div />
									<Button content={"Save and Continue"} onClick={handleSave} />
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</>
			) : (
				<>
					<Flex vertical align="center">
						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<Flex style={{ textAlign: "left" }}>
								<TitleText text="Here are your business’s distribution channels:" />
							</Flex>
							<div className={styles.formSpacing} />
							{channels.map((c, index) => (
								<BodyText key={`${index}-${c}`} text={`${index + 1}.  ${c}`} />
							))}
						</Flex>

						<div className={styles.formSpacing} />

						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<Flex style={{ textAlign: "left" }}>
								<TitleText text="Here are your business’s promotion channels:" />
							</Flex>
							<div className={styles.formSpacing} />
							<SubheaderText isHeavy text="Raise Awareness:" />
							{customer.map((c, index) => (
								<BodyText key={`${index}-${c}`} text={`${index + 1}.  ${c}`} />
							))}

							<div className={styles.formSpacing} />
							<SubheaderText isHeavy text="Educate:" />
							{learnMore.map((c, index) => (
								<BodyText key={`${index}-${c}`} text={`${index + 1}.  ${c}`} />
							))}
							<div className={styles.formSpacing} />
							<SubheaderText isHeavy text="Stay in Touch:" />
							{stayInTouch.map((c, index) => (
								<BodyText key={`${index}-${c}`} text={`${index + 1}.  ${c}`} />
							))}
						</Flex>

						<div className={styles.formSpacing} />
					</Flex>
					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleContinue} />
					</Flex>
				</>
			)}
		</Flex>
	);
};

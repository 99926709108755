import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material";
import { theme } from "./theme";
import "./App.css";
import "./global.css";
import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import { Provider } from "react-redux";
import CapitalPage from "./pages/CapitalPage/CapitalPage";
import WelcomeCard from "./components/Shared/WelcomeCard/WelcomeCard";
import Questionnaire from "./components/Shared/Questionnaire/Questionnaire";
import OverlayPage from "./pages/OverlayPage/OverlayPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import BusinessSummaryPage from "./pages/BusinessSummaryPage/BusinessSummary";
import BusinessSummaryFormPage from "./pages/BusinessSummaryPage/BusinessSummaryForm";
import CourseLanding from "./pages/courses/CourseLanding";
import VisionMissionValues from "./pages/BusinessSummaryPage/FormTemplate.jsx";
import ProfilePage from "./pages/ProfilePage/ProfilePage.jsx";
import ContactPage from "./pages/ContactPage/ContactPage.jsx";
import "firebaseui/dist/firebaseui.css";
import { store } from "./redux/index";
import useAuth from "./auth/useAuth";
import TermsServicePage from "./pages/TermsServicePage/TermsServicePage.jsx";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage.jsx";
import RegistrationPage from "./components/RegistrationPage/RegistrationPage.jsx";
import LoginPage from "./components/LoginPage/LoginPage.jsx";
import PasswordResetPage from "./components/PasswordResetPage/PasswordResetPage.jsx";

function App() {
  return (
    <CssVarsProvider theme={theme}>
      <Provider store={store}>
        <Routes>
          <Route
            path="/*"
            element={
              useAuth()?.isAuthenticated ? (
                <Navigate to="/login" />
              ) : (
                // <OverlayPage
                //   isUser={useAuth.isAuthenticated}
                //   Component={RegistrationPage}
                // />
                <RegistrationPage/>
              )
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/password-reset" element={<PasswordResetPage />} />
          <Route
            path="/register"
            element={<RegistrationPage isUser={false} />}
          />

          {/* is /welcome route working? */}
          <Route
            path="/welcome"
            element={
              <ProtectedRoute>
                <LandingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-user"
            element={
              <ProtectedRoute>
                <OverlayPage isUser={false} Component={Questionnaire} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <LandingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/courses"
            element={
              <ProtectedRoute>
                <CourseLanding />
              </ProtectedRoute>
            }
          />
          <Route
            path="/business"
            element={
              <ProtectedRoute>
                <BusinessSummaryPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/business-tool"
            element={
              <ProtectedRoute>
                <BusinessSummaryFormPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/capital"
            element={
              <ProtectedRoute>
                <CapitalPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mission"
            element={
              <ProtectedRoute>
                <VisionMissionValues />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedRoute>
                <ContactPage />
              </ProtectedRoute>
            }
          />
          <Route path="/terms-service" element={<TermsServicePage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
        </Routes>
      </Provider>
    </CssVarsProvider>
  );
}

export default App;

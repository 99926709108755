// Don't forget to
// // download the CSS file too OR
// // remove the if Tailwind
import styles from "./PasswordResetPage.module.css";
import { useState } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import CentroLogo from "../../assets/Centro_Logo_Horizontal.svg?react";
import SignInTeam from "../../assets/pavan_6416_new.png";
import Input from "../Shared/Input/Input";
import Checkbox from "../Shared/Checkbox/Checkbox";
import Button from "../Shared/Button/Button";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setUserObject } from "../../redux/slices/userSlice";
import { setRefreshToken, setIsUser } from "../../redux/slices/authSlice";
import { login, signUp } from "../../utils/analytics";

export default function PasswordResetPage() {
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const isUser = useSelector((state) => state.auth.isUser);
  const dispatch = useDispatch();
  // Inputs
  const [isReturningUser, setIsReturningUser] = useState(isUser ?? false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  // Checkbox
  const [isRemember, setIsRemember] = useState(email ? true : false);
  const [EmailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user ?? {};
        const token = userCredential.user.stsTokenManager.refreshToken ?? "";
        dispatch(setUserObject(user));
        dispatch(setRefreshToken(token));
        dispatch(setIsUser("true"));
        navigate("/home");
        login("email_pw");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleSignUp = async () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user ?? {};
        const token = userCredential.user.stsTokenManager.refreshToken ?? "";
        dispatch(setUserObject(user));
        dispatch(setRefreshToken(token));
        dispatch(setIsUser("true"));
        navigate("/home");
        signUp("email_pw");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  // TODO TOGGLE THIS BASED ON WHEHTER API SAYS USER EXISTS
  const handleGoogleAccount = (name) => {
    if (name?.length > 0) {
      login("email_pw");
      navigate("/home");
    } else {
      signUp("email_pw");
      navigate("/home");
    }
  };

  const handleGoogle = async () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        dispatch(setRefreshToken(token));
        dispatch(setIsUser("true"));
        const user = result?.user?.reloadUserInfo ?? {};
        dispatch(setUserObject(user));
        handleGoogleAccount(user?.displayName);
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAgreeTerms((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const comparePasswords = (password1, password2) => {
    return password1 === password2;
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (!comparePasswords(password, e.target.value)) {
      setEmailSent(true);
    } else {
      setEmailSent(false);
    }
  };
  const resetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        setEmailSent(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles["info-section"]}>
          <div className={styles["logo-container"]}>
            <CentroLogo className={styles.logo} />
          </div>
          <h2 className={styles["info-title"]}>Ready to get back to it?</h2>
          <p className={styles["info-text"]}>
            Sign in to continue building you business!
          </p>
          <div className={styles["illustration-container"]}>
            <img src={SignInTeam} alt="Illustration of people collaborating" />
          </div>
        </div>
        <div className={styles["form-section"]}>
          <h2 className={styles.title}>Reset Password</h2>
          <form>
            <div className={styles["input-group"]}>
              <label htmlFor="email">Email</label>
              <Input
                id="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                setValue={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {EmailSent && (
              <Alert severity="error">
                If a user with that email exists, a password reset email has
                been sent
              </Alert>
            )}
            <Button
              // isDisabled={passwordError || !agreeTerms || !email || !phone}
              onClick={resetPassword}
              content={"Reset Password"}
            />
          </form>
          <small className={styles["route-link"]}>
            <a
              className={styles.link}
              href="#"
              onClick={() => navigate("/login")}
            >
              Back to Login Page
            </a>
          </small>
        </div>
      </div>
    </>
  );
}
PasswordResetPage.propTypes = {
  /**
   * Determines the status of the user’s registration.
   * If true, display “welcome back” content.
   */
  isUser: PropTypes.bool,
  /**
   * Determines if the login info was previously stored.
   * If stored, prefill email/password if previously saved and default checkbox to “true”.
   */
  login: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
};

PasswordResetPage.defaultProps = {
  login: { email: "", password: "" },
};

import { Flex } from "antd";
import Button from "../../components/Shared/Button/Button";
import Placeholder from "../../assets/Placeholder.svg";
import styles from "./CapitalOptions.module.css";

export const Option = ({
	img = null,
	name = "",
	description = "",
	url = "",
}) => {
	return (
		<Flex vertical className={styles.optionContainer}>
			<Flex className={styles.optionImageContainer}>
				<img
					className={styles.optionImage}
					src={img === null ? Placeholder : img}
					alt={`${name} logo`}
				/>
			</Flex>
			<Flex
				vertical
				justify="space-between"
				className={styles.contentContainer}
			>
				<p className={styles.optionName}>{name}</p>
				<p className={styles.optionDescription}>{description}</p>
				<Flex horizontal justify="flex-end">
					<a href={url} target="_blank" rel="noopener noreferrer">
						<Button content="Learn More" />
					</a>
				</Flex>
			</Flex>
		</Flex>
	);
};

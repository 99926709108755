import { useState, useEffect } from "react";
import styles from "./Questionnaire.module.css";
import MenuItem from "@mui/material/MenuItem";
import { Card } from "@mui/material";
import { Form, Row, Col, Flex } from "antd";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { logScreen } from "../../../utils/analytics";
import {
  countryList,
  genderList,
  raceList,
  multiRacialList,
  incomeList,
  edList,
} from "../../../utils/profileData";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { setQuestionnaireData } from "../../../redux/slices/profileSlice";
import { useDispatch } from "react-redux";
import { Check } from "@mui/icons-material";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import dayjs from "dayjs";

export default function Questionnaire() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    phone: "",
    businessEmail: "",
    country: "",
    zip: "",
    dob: "",
    gender: "",
    race: "",
    multiRace: "",
    income: "",
    education: "",
  });
  const [isRemember, setIsRemember] = useState(
    data.businessEmail ? true : false
  );

  const GreenCheckbox = withStyles({
    root: {
      color: "#C2C9D1",
      "&$checked": {
        color: "#05B452",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  useEffect(() => {
    logScreen("QUESTIONNAIRE");
  }, []);

  const updateField = (fieldName, value) => {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const updateUserInfo = () => {
    // TODO UPDATE DATA IN THE API
    dispatch(setQuestionnaireData(data));
    navigate("/home");
  };

  return (
    <Card className={styles.card}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ width: "100%" }}
        initialValues={{ remember: true }}
        // onFinish={onFinish} // TODO WHAT DOES THIS DO
        autoComplete="off"
        className={{ minWidth: 120 }}
      >
        <Row
          justify="space-evenly"
          align="middle"
          style={{ padding: "0 180px" }}
          gutter={32}
        >
          <Col span={24}>
            <p className={styles.header}>Tell us a bit about yourself</p>
          </Col>

          <Col span={24}>
            <p className={styles.subheader}>Contact Information</p>
          </Col>

          <Col span={12} align="center" style={{ margin: "10px 0" }}>
            <Input
              label="Phone"
              placeholder="Enter your Phone Number"
              value={data.phone}
              setValue={(e) => updateField("phone", e.target.value)}
            />
          </Col>

          <Col span={12} align="center" style={{ margin: "10px 0" }}>
            <Input
              label="Business Email (optional)"
              placeholder="Enter email"
              value={data.businessEmail}
              setValue={(e) => updateField("businessEmail", e.target.value)}
            />
          </Col>

          <Col span={24}>
            <p className={styles.subheader}>Personal Details</p>
          </Col>

          <Col span={12} align="center" style={{ margin: "10px 0" }}>
            <p className={styles.appDropdownLabel}>Country</p>
            <Box sx={{ minWidth: 300 }}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: 300,
                  borderRadius: 8,
                  "& label.Mui-focused": {
                    color: "#00000080",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00000080",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B4FED4",
                    },
                  },
                }}
              >
                <Select
                  disableUnderline
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.country}
                  onChange={(e) => updateField("country", e.target.value)}
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  {countryList.map((option) => (
                    <MenuItem
                      sx={{
                        minWidth: 300,
                        "&:hover": {
                          backgroundColor: "#EEFFF4",
                        },
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      <Flex horizontal align="center" justify="space-between">
                        {<p className={styles.appFormLabel}>{option.label}</p>}
                        <div style={{ width: 180 }} />
                        {option.label === data.country && (
                          <Check fontSize="60" sx={{ color: "#05B452" }} />
                        )}
                      </Flex>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Col>

          <Col
            span={12}
            align="center"
            style={{ margin: "10px 0", paddingTop: "17px" }}
          >
            <Input
              label="Zip Code (optional)"
              placeholder="Enter ZIP"
              value={data.zip}
              setValue={(e) => updateField("zip", e.target.value)}
            />
          </Col>

          <Col
            span={12}
            align="center"
            style={{ margin: "10px 0", paddingTop: "17px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="DOB"
                className={styles.inputWrapper}
                value={dayjs("2024-06-01")}
                onChange={(d) => {
                  const date = JSON.stringify(d);
                  updateField("dob", date);
                }}
                sx={{
                  borderRadius: 2,
                  ".Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#05B452",
                    borderWidth: 1,
                  },
                }}
                slotProps={{
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#05B452",
                      },
                      ":hover": {
                        backgroundColor: "#EEFFF4",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Col>

          <Col span={12} align="center" style={{ margin: "10px 0" }}>
            <p className={styles.appDropdownLabel}>Gender</p>
            <Box sx={{ minWidth: 300 }}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: 300,
                  borderRadius: 8,
                  "& label.Mui-focused": {
                    color: "#00000080",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00000080",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B4FED4",
                    },
                  },
                }}
              >
                <Select
                  disableUnderline
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.gender}
                  onChange={(e) => updateField("gender", e.target.value)}
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  {genderList.map((option) => (
                    <MenuItem
                      sx={{
                        minWidth: 300,
                        "&:hover": {
                          backgroundColor: "#EEFFF4",
                        },
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      <Flex horizontal align="center" justify="space-between">
                        {<p className={styles.appFormLabel}>{option.label}</p>}
                        <div style={{ width: 180 }} />
                        {option.label === data.gender && (
                          <Check fontSize="60" sx={{ color: "#05B452" }} />
                        )}
                      </Flex>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Col>

          <Col span={12} align="center" style={{ margin: "10px 0" }}>
            <p className={styles.appDropdownLabel}>Race</p>
            <Box sx={{ minWidth: 300 }}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: 300,
                  borderRadius: 8,
                  "& label.Mui-focused": {
                    color: "#00000080",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00000080",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B4FED4",
                    },
                  },
                }}
              >
                <Select
                  disableUnderline
                  id="demo-simple-select"
                  value={data.race}
                  onChange={(e) => updateField("race", e.target.value)}
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  {raceList.map((option) => (
                    <MenuItem
                      sx={{
                        minWidth: 300,
                        "&:hover": {
                          backgroundColor: "#EEFFF4",
                        },
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      <Flex horizontal align="center" justify="space-between">
                        {<p className={styles.appFormLabel}>{option.label}</p>}
                        <div style={{ width: 180 }} />
                        {option.label === data.race && (
                          <Check fontSize="60" sx={{ color: "#05B452" }} />
                        )}
                      </Flex>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Col>

          <Col span={12} align="center" style={{ margin: "10px 0" }}>
            <p className={styles.appDropdownLabel}>Multi-Racial (optional)</p>
            <Box sx={{ minWidth: 300 }}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: 300,
                  borderRadius: 8,
                  "& label.Mui-focused": {
                    color: "#00000080",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00000080",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B4FED4",
                    },
                  },
                }}
              >
                <Select
                  disableUnderline
                  id="demo-simple-select"
                  value={data.multiRace}
                  onChange={(e) => updateField("multiRace", e.target.value)}
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  {multiRacialList.map((option) => (
                    <MenuItem
                      sx={{
                        minWidth: 300,
                        "&:hover": {
                          backgroundColor: "#EEFFF4",
                        },
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      <Flex horizontal align="center" justify="space-between">
                        {<p className={styles.appFormLabel}>{option.label}</p>}
                        <div style={{ width: 180 }} />
                        {option.label === data.multiRace && (
                          <Check fontSize="60" sx={{ color: "#05B452" }} />
                        )}
                      </Flex>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Col>

          <Col span={12} align="center" style={{ margin: "10px 0" }}>
            <p className={styles.appDropdownLabel}>Income</p>
            <Box sx={{ minWidth: 300 }}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: 300,
                  borderRadius: 8,
                  "& label.Mui-focused": {
                    color: "#00000080",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00000080",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B4FED4",
                    },
                  },
                }}
              >
                <Select
                  disableUnderline
                  id="demo-simple-select"
                  value={data.income}
                  onChange={(e) => updateField("income", e.target.value)}
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  {incomeList.map((option) => (
                    <MenuItem
                      sx={{
                        minWidth: 300,
                        "&:hover": {
                          backgroundColor: "#EEFFF4",
                        },
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      <Flex horizontal align="center" justify="space-between">
                        {<p className={styles.appFormLabel}>{option.label}</p>}
                        <div style={{ width: 180 }} />
                        {option.label === data.income && (
                          <Check fontSize="60" sx={{ color: "#05B452" }} />
                        )}
                      </Flex>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Col>

          <Col span={12} align="center" style={{ margin: "10px 0" }}>
            <p className={styles.appDropdownLabel}>Education</p>
            <Box sx={{ minWidth: 300 }}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: 300,
                  borderRadius: 8,
                  "& label.Mui-focused": {
                    color: "#00000080",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00000080",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B4FED4",
                    },
                  },
                }}
              >
                <Select
                  disableUnderline
                  id="demo-simple-select"
                  value={data.education}
                  onChange={(e) => updateField("education", e.target.value)}
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  {edList.map((option) => (
                    <MenuItem
                      sx={{
                        minWidth: 300,
                        "&:hover": {
                          backgroundColor: "#EEFFF4",
                        },
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      <Flex horizontal align="center" justify="space-between">
                        {<p className={styles.appFormLabel}>{option.label}</p>}
                        <div style={{ width: 180 }} />
                        {option.label === data.education && (
                          <Check fontSize="60" sx={{ color: "#05B452" }} />
                        )}
                      </Flex>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Col>

          <Flex
            horizontal
            align="center"
            justify="flex-start"
            style={{ paddingBottom: 30 }}
          >
            <FormControlLabel
              control={
                <GreenCheckbox
                  name="remember"
                  onChange={(e) => setIsRemember(e.target.checked)}
                  checked={isRemember}
                />
              }
            />
            <p className={styles.appCheckboxLabel}>
              Check this box if you'd like to sign up for our email newsletter
              and opt in to participate in future surveys.
            </p>
          </Flex>

          <Col span={24} align="center" style={{ margin: "-15px 0 20px 0" }}>
            <Button content={"Get Started"} onClick={updateUserInfo} />
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

// Don't forget to
// // download the CSS file too OR
// // remove the if Tailwind
import styles from "./LoginPage.module.css";
import { useState } from "react";
import PropTypes from "prop-types";
import facebookLogo from "../../assets/Facebook.png";

import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import CentroLogo from "../../assets/Centro_Logo_Horizontal.svg?react";
import SignInTeam from "../../assets/pavan_6416_new.png";
import Input from "../Shared/Input/Input";
import Checkbox from "../Shared/Checkbox/Checkbox";
import Button from "../Shared/Button/Button";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setUserObject } from "../../redux/slices/userSlice";
import { setRefreshToken, setIsUser } from "../../redux/slices/authSlice";
import { login, signUp } from "../../utils/analytics";

export default function LoginPage() {
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const isUser = useSelector((state) => state.auth.isUser);
  const dispatch = useDispatch();
  // Inputs
  const [isReturningUser, setIsReturningUser] = useState(isUser ?? false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  // Checkbox
  const [isRemember, setIsRemember] = useState(email ? true : false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user ?? {};
        const token = userCredential.user.stsTokenManager.refreshToken ?? "";
        dispatch(setUserObject(user));
        dispatch(setRefreshToken(token));
        dispatch(setIsUser("true"));
        navigate("/home");
        login("email_pw");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleSignUp = async () => {
    if(!formIsValid()){
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user ?? {};
        const token = userCredential.user.stsTokenManager.refreshToken ?? "";
        dispatch(setUserObject(user));
        dispatch(setRefreshToken(token));
        dispatch(setIsUser("true"));
        navigate("/home");
        signUp("email_pw");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  // TODO TOGGLE THIS BASED ON WHEHTER API SAYS USER EXISTS
  const handleGoogleAccount = (name) => {
    if (name?.length > 0) {
      login("email_pw");
      navigate("/home");
    } else {
      signUp("email_pw");
      navigate("/home");
    }
  };

  const handleGoogle = async () => {
    if(!formIsValid()){
      return;
    }
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        dispatch(setRefreshToken(token));
        dispatch(setIsUser("true"));
        const user = result?.user?.reloadUserInfo ?? {};
        dispatch(setUserObject(user));
        handleGoogleAccount(user?.displayName);
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  const handleFacebookAccount = (name) => {
    if (name?.length > 0) {
      login("email_pw");
      navigate("/home");
    } else {
      signUp("email_pw");
      navigate("/home");
    }
  };

  const handleFacebook = async () => {
    
    signInWithPopup(auth, facebookProvider)
      .then((result) => {
        // The signed-in user info.
        // const user = result.user
        console.log(result.user);
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        dispatch(setRefreshToken(accessToken));
        dispatch(setIsUser("true"));
        const user = result?.user?.reloadUserInfo ?? {};
        dispatch(setUserObject(user));
        handleFacebookAccount(user?.displayName);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        console.log(error);
        // const credential = FacebookAuthProvider.credentialFromError(error);
        // ...
      });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAgreeTerms((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  

  // const handleConfirmPasswordChange = (e) => {
  //   setConfirmPassword(e.target.value);
  //   if (!comparePasswords(password, e.target.value)) {
  //     return false
  //   }
  // };

  // const agreeTermsChecked = ()=>{
  //   if(!agreeTerms){
  //     setError("Please accept Terms and Privacy Policy to continue!");
  //   }
  // }

  // const formIsValid = () => {
  //   if(!email ){
  //     setError("Please fill out email field!");
  //     return false;
  //   }
  //   if(!comparePasswords(password, confirmPassword)){
  //     setError("Passwords do not match!");
  //     return false;
  //   }
  //   if(!agreeTerms){
  //     setError("Please accept Terms and Privacy Policy to continue!");
  //     return false;
  //   }
  //   else{
  //     return true;
  //   }
  // }

  return (
    <>
      <div className={styles.container}>
        <div className={styles["info-section"]}>
          <div className={styles["logo-container"]}>
            <CentroLogo className={styles.logo} />
          </div>
          <h2 className={styles["info-title"]}>Ready to get back to it?</h2>
          <p className={styles["info-text"]}>
            Sign in to continue building you business!
          </p>
          <div className={styles["illustration-container"]}>
            <img src={SignInTeam} alt="Illustration of people collaborating" />
          </div>
        </div>
        <div className={styles["form-section"]}>
          <h2 className={styles.title}>Hello!</h2>
          <form>
            <div className={styles["input-group"]}>
              <label htmlFor="email">Email</label>
              <Input
                id="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                setValue={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className={styles["input-group"]}>
              <label htmlFor="password">Password</label>
              <Input
                id="password"
                type="password"
                name="password"
                placeholder="Create a Password"
                value={password}
                setValue={(e) => setPassword(e.target.value)}
                required
                isCanHide={true}
              />
            </div>
            {error && (
              <Alert severity="error">{error}</Alert>
            )}

            <div className={styles["password-actions"]}>
              {/* <Checkbox
                label="Remember me"
                isChecked={isRemember}
                setIsChecked={(e) => setIsRemember(e.target.checked)}
              /> */}
              <a
                className={styles.link}
                href="#"
                onClick={() => navigate("/password-reset")}
              >
                Forgot password?
              </a>
            </div>
            <div className={styles["button-group"]}>
              <Button
                onClick={ handleSignUp}
                content={"Sign in"}
              />
              <div className={styles.divider}>
                <span />
                <p>or</p>
                <span />
              </div>

              <Button
                onClick={handleGoogle}
                content={
                  <>
                    <div className={styles["gsi-material-button-state"]}></div>
                    <div
                      className={styles["gsi-material-button-content-wrapper"]}
                    >
                      <div className={styles["gsi-material-button-icon"]}>
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          style={{ display: "block" }}
                        >
                          <path
                            fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                          ></path>
                          <path
                            fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                          ></path>
                          <path
                            fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                          ></path>
                          <path
                            fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                          ></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                      </div>
                      <span className={styles["gsi-material-button-contents"]}>
                        Sign in with Google
                      </span>
                    </div>
                  </>
                }
                className={styles["gsi-material-button"]}
              />
              <Button
                onClick={handleFacebook}
                content={
                  <>
                    <div className={styles["gsi-material-button-state"]}></div>
                    <div
                      className={styles["gsi-material-button-content-wrapper"]}
                    >
                      <div className={styles["gsi-material-button-icon"]}>
                        <img src={facebookLogo} alt="Facebook Logo" />
                      </div>
                      <span className={styles["gsi-material-button-contents"]}>
                        Sign in with Facebook
                      </span>
                    </div>
                  </>
                }
                className={styles["gsi-material-button"]}
              />
            </div>
          </form>
          <small className={styles["route-link"]}>
            {"Don't have an account? "}
            <a
              className={styles.link}
              href="#"
              onClick={() => navigate("/register")}
            >
              Sign Up
            </a>
          </small>
        </div>
      </div>
    </>
  );
}
LoginPage.propTypes = {
  /**
   * Determines the status of the user’s registration.
   * If true, display “welcome back” content.
   */
  isUser: PropTypes.bool,
  /**
   * Determines if the login info was previously stored.
   * If stored, prefill email/password if previously saved and default checkbox to “true”.
   */
  login: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
};

LoginPage.defaultProps = {
  login: { email: "", password: "" },
};

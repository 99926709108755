import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import authSlice from "./slices/authSlice";
import userSlice from "./slices/userSlice";
import businessSlice from "./slices/businessSlice";
import profileSlice from "./slices/profileSlice";

const persistConfig = {
	key: "root",
	storage,
};

const rootReducer = combineReducers({
	auth: authSlice,
	user: userSlice,
	business: businessSlice,
	profile: profileSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export const persistor = persistStore(store);

import { useState } from "react";
import NavDrawer from "../../components/Shared/NavDrawer/NavDrawer";
// import ChatButton from "../../components/chat/ChatButton";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Row, Col, Flex } from "antd";
import Media from "../../components/assets/images/Media.png";
import CourseCard from "../../components/courseCard/CourseCard";
import "./CourseLanding.css";
import { programData } from "../../utils/programData";
import { courseData } from "../../utils/courseData";

function CourseLanding() {
  const [state, setState] = useState("ProgramsCourses");

  // TODO THIS SHOULD BE DYNAMIC FROM THE DB/API --sic
  const myPrograms = [
    {
      tag: "Tag 1",
      title: "Title 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci justo, aliquet et ligula non, fringilla volutpat nunc.",
      total: "1 Courses",
      button: "program",
    },
    {
      tag: "Tag 2",
      title: "Title 2",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci justo, aliquet et ligula non, fringilla volutpat nunc.",
      total: "2 Courses",
      button: "program",
    },
  ];

  // TODO THIS SHOULD BE DYNAMIC FROM THE DB/API --sic
  const myCourses = [
    {
      tag: "Tag 1",
      title: "Title 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci justo, aliquet et ligula non, fringilla volutpat nunc.",
      total: "1 Courses",
      button: "program",
    },
  ];

  const ProgramsCourses = () => {
    return (
      <>
        <Flex vertical style={{ width: "100%", textAlign: "left" }}>
          <p className="subsection">Programs</p>
        </Flex>
        <Flex vertical style={{ width: "100%", textAlign: "left" }}>
          <Flex wrap="wrap" gap={20}>
            {programData.map((course, index) => (
              <CourseCard key={index} {...course} />
            ))}
          </Flex>
        </Flex>
        <Flex vertical style={{ width: "100%", textAlign: "left" }}>
          <p className="subsection">Courses</p>
        </Flex>
        <Flex vertical style={{ width: "100%", textAlign: "left" }}>
          <Flex wrap="wrap" gap={20}>
            {courseData.map((course, index) => (
              <CourseCard key={index} {...course} />
            ))}
          </Flex>
        </Flex>
      </>
    );
  };

  const MyLearning = () => {
    return (
      <>
        <Flex vertical style={{ width: "100%", textAlign: "left" }}>
          <p className="subsection">Programs</p>
        </Flex>
        <Flex vertical style={{ width: "100%", textAlign: "left" }}>
          <Flex wrap="wrap" gap={20}>
            {myPrograms.map((course, index) => (
              <CourseCard key={index} {...course} />
            ))}
          </Flex>
        </Flex>
        <Flex vertical style={{ width: "100%", textAlign: "left" }}>
          <p className="subsection">Courses</p>
        </Flex>
        <Flex vertical style={{ width: "100%", textAlign: "left" }}>
          <Flex wrap="wrap" gap={20}>
            {myCourses.map((course, index) => (
              <CourseCard key={index} {...course} />
            ))}
          </Flex>
        </Flex>
      </>
    );
  };

  const states = {
    ProgramsCourses: ProgramsCourses,
    MyLearning: MyLearning,
  };

  return (
    <>
      <NavDrawer />
      {/*
        Note: due to delivery constraints this is pushed out
      to V2. -sic
      <ChatButton />
      */}
      <div style={{ marginLeft: "16rem", position: "relative", zIndex: 1 }}>
        <Row style={{ padding: "44px 32px 32px 56px", margin: "0" }}>
          <Col span={24} style={{ padding: "0", margin: "0" }}>
            <Flex vertical justify="flex-start" align="center" gap={24}>
              <Flex
                style={{ width: "100%" }}
                justify="flex-start"
                align="center"
              >
                <p className="title">Courses</p>
              </Flex>

              <ButtonGroup
                disabled={false}
                orientation="horizontal"
                size="large"
                variant="outlined"
                sx={{
                  p: 2,
                  padding: "0",
                  width: "100%",
                  display: "flex",
                }}
              >
                <Button
                  className="SectionButton"
                  onClick={() => {
                    setState("ProgramsCourses");
                  }}
                >
                  Summary
                </Button>
                <Button
                  className="SectionButton"
                  onClick={() => {
                    setState("MyLearning");
                  }}
                >
                  My Learning
                </Button>
              </ButtonGroup>
              {states[state]()}
            </Flex>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CourseLanding;

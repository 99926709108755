import { useState, useEffect } from "react";
import Button from "../../components/Shared/Button/Button";
import { Row, Col, Flex } from "antd";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import styles from "./BusinessSummary.module.css";
// import ChatButton from "../../components/chat/ChatButton";
import NavDrawer from "../../components/Shared/NavDrawer/NavDrawer";
// import SummarySection from "./SummarySection";
import BusinessPlanningToolSection from "./BusinessPlan/BusinessPlanningToolSection";
// import VissionMissionValues from "./FormTemplate";
import { logScreen } from "../../utils/analytics";
import { useSelector, useDispatch } from "react-redux";
import { setProgress } from "../../redux/slices/businessSlice";
import HeadlineText from "../../components/Shared/Text/HeadlineText.jsx";

import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();
const testEmail = httpsCallable(functions, 'testemailfromapp');

const BusinessSummaryPage = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState("business");
  const progress = useSelector(
    (state) => state.business.businessToolProgress ?? 0,
  );
  const bizData = useSelector((state) => state.business.businessToolData);
  const userEmail = useSelector((state) => state.user.user);

  useEffect(() => {
    logScreen("BUSINESS_SUMMARY");
  }, []);

  // const PersonalFinanceSection = () => {
  //   return (
  //     <Flex style={{ width: "100%" }} vertical justify="center" align="center">
  //       <Flex vertical className={styles.Card} gap={24}>
  //         <h1 className={styles.cardTitle}>Financial Freedom Plan:</h1>
  //         <p className={styles.cardSubsection}>
  //           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
  //           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
  //           ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
  //           aliquip ex ea commodo consequat.
  //         </p>
  //       </Flex>
  //     </Flex>
  //   );
  // };

  // const LeadershipSection = () => {
  //   return (
  //     <Flex style={{ width: "100%" }} vertical justify="center" align="center">
  //       <Flex vertical className={styles.Card} gap={24}>
  //         <h1 className={styles.cardTitle}>Leadership:</h1>
  //         <p className={styles.cardSubsection}>
  //           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
  //           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
  //           ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
  //           aliquip ex ea commodo consequat.
  //         </p>
  //       </Flex>
  //     </Flex>
  //   );
  // };

  const states = {
    // summary: <SummarySection />,
    business: (
      <BusinessPlanningToolSection
        setState={setState}
        currentProgress={progress}
      />
    ),
    // personal: <PersonalFinanceSection />,
    // leadership: <LeadershipSection />,
    // mission: <VissionMissionValues setState={setState} />,
  };

  const resetForm = () => {
    dispatch(setProgress(0));
  };

  const emailForm = async () => {
    try {
//alert(userEmail.email);

testEmail({ recipient: userEmail.email, summary: bizData})
  .then((result) => {
    // Read result of the Cloud Function.
    /** @type {any} */
    const data = result.data;
    const sanitizedMessage = data.text;

    alert('Email sent to ' + userEmail.email + ' (check your Spam folder)');


  }).catch((error) => {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    const details = error.details;
    // ...

   // alert(code);

    alert('Email sent to ' + userEmail.email + ' (check your Spam folder)');

  });
/*
      fetch(import.meta.env.VITE_FIREBASE_API_URL, {
        method: "POST",
        body: JSON.stringify({ bizData }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          // "Access-Control-Allow-Origin": import.meta.env.VITE_FIREBASE_API_URL,
          "Access-Control-Allow-Origin": "*",
        },
      });
    */

    } catch (e) {
      console.log("emailForm error::", e);
    }

  };

  return (
    <>
      <NavDrawer />
      {/*
        Note: due to delivery constraints this is pushed out
      to V2. -sic
      <ChatButton />
      */}
      <div style={{ marginLeft: "16rem", position: "relative", zIndex: 1 }}>
        <Row style={{ padding: "44px 32px 32px 56px", margin: "0" }}>
          <Col span={24} style={{ padding: "0", margin: "0" }}>
            <Flex vertical justify="flex-start" gap={24}>
              <Flex
                style={{ width: "100%", paddingLeft: 40 }}
                justify="flex-start"
                align="center"
              >
                <HeadlineText isHeavy text="Business Summary" />
              </Flex>

              {/*<ButtonGroup
                              disabled={false}
                              orientation="horizontal"
                              size="large"
                              variant="outlined"
                              sx={{
                                p: 2,
                                padding: "0",
                                width: "100%",
                                display: "flex",
                              }}
                            >
                                            <Button
                                className={styles.SectionButton}
                                onClick={() => {
                                  setState("summary");
                                }}
                              >
                                Summary
                              </Button>
                              <Button
                                className={styles.SectionButton}
                                onClick={() => {
                                  setState("business");
                                }}
                              >
                                Business Planning Tool
                              </Button>
                              <Button
                                className={styles.SectionButton}
                                onClick={() => {
                                  setState("personal");
                                }}
                              >
                                Personal Finance
                              </Button>
                              <Button
                                className={styles.SectionButton}
                                onClick={() => {
                                  setState("leadership");
                                }}
                              >
                                Leadership
                              </Button>
                            </ButtonGroup>*/}
              {states[state]}
            </Flex>
            <Flex horizontal style={{ paddingLeft: 40 }}>
              <Button onClick={resetForm} content="Reset form" />
              <div style={{ width: 20 }} />
              <Button onClick={emailForm} content="Email summary" />
            </Flex>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BusinessSummaryPage;

import { useState, useEffect } from "react";
import { setRefreshToken, setIsUser } from "../redux/slices/authSlice";

// get the stored refreshToken if it's there
// if it's not there, prompt log in

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const appAuth = () => {
      const token = localStorage.getItem("refreshToken");

      if (token === null) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
        setRefreshToken(token);
        setIsUser("true");
      }

      setLoading(false);
    };

    appAuth();
  });

  return { isAuthenticated, loading };
};

export default useAuth;

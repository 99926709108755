import "./CourseCard.css";
import Media from "../assets/images/Media.png";
import { Flex } from "antd";

const CourseCard = ({ tag, title, description, total, button, image }) => {
  return (
    <Flex align="flex-start" vertical className="CourseCardContainer">
      <img src={image} />
      <Flex
        vertical
        justify="space-between"
        align="flex-start"
        className="CourseCardTextContainer"
      >
        <Flex
          vertical
          className="CourseCardHeader"
          align="flex-start"
          justify="flex-start"
          gap={8}
        >
          <div className="CourseCardTag">{tag}</div>
          <div className="CourseCardTitle">{title}</div>
        </Flex>

        <div className="CourseCardDescription">{description}</div>

        <div className="CourseCardBottomContainer">
          <div className="CourseCardLesson">{total}</div>
          <button style={{ color: "#FFF" }}>See {button}</button>
        </div>
      </Flex>
    </Flex>
  );
};

export default CourseCard;

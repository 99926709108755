import PropTypes from "prop-types";
import styles from "./Button.module.css";

import { Button as _Button } from "@mui/material-next";

/**
 * Interactive multi-purpose button.
 */
export default function Button({ onClick, content, className, isDisabled }) {
  return (
    <_Button
      className={className ? className : styles.primary}
      onClick={onClick}
      disabled={isDisabled}
      sx={[
        {
          "&:hover": {
            boxShadow:
              "0 1px 2px rgba(0, 0, 0, 30%), inset 0 0 0 10em rgba(250, 250, 250, 0.08)",
          },
        },
      ]}
    >
      {content}
    </_Button>
  );
}

Button.propTypes = {
  /**
   * Fires when the button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Renders button content.
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Optional classname.
   */
  className: PropTypes.string,
};

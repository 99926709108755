import styles from "./FormInput.module.css";
import { Flex } from "antd";

export default function FormInput({
  inputName,
  placeholder,
  value,
  onChange,
  isRequired,
  inputStyle,
  hasLabel,
  formLabel,
  isMultiline,
  isBox,
  inputType,
}) {
  if (isBox) {
    return (
      <Flex vertical align="flex-start" justify="flex-start">
        {hasLabel && <p className={styles.appFormLabel}>{formLabel}</p>}
        <input
          required={isRequired}
          className={styles.appFormInputSquare}
          name={inputName}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={inputType}
        />
      </Flex>
    );
  } else {
    return (
      <Flex vertical align="flex-start" justify="flex-start">
        {hasLabel && <p className={styles.appFormLabel}>{formLabel}</p>}
        <input
          required={isRequired}
          className={
            isMultiline ? styles.appFormInputMultiline : styles.appFormInput
          }
          name={inputName}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={inputType}
        />
      </Flex>
    );
  }
}

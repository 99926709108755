import { useState } from "react";
import styles from "../../BusinessPlanningTool.module.css";
import { Flex } from "antd";
import Button from "../../../../components/Shared/Button/Button";
import { useNavigate } from "react-router-dom";
import {
	legalEntities,
	bizStages,
	wisdomKnowledgeValues,
	courageValues,
	humanityValues,
	justiceValues,
	temperanceValues,
	transcendenceValues,
} from "../../../../utils/visionData";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import {
	setProgress,
	setBusinessToolData,
} from "../../../../redux/slices/businessSlice";
import { useDispatch, useSelector } from "react-redux";
import RedHair from "../../../../assets/BusinessPlanningTool/people_images/red_hair.png";
import FormInput from "../../../../components/Shared/FormInput/FormInput";
import TitleText from "../../../../components/Shared/Text/TitleText.jsx";
import BodyText from "../../../../components/Shared/Text/BodyText.jsx";
import SubheaderText from "../../../../components/Shared/Text/SubheaderText.jsx";

export const VisionMissionValuesForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [showSummary, setShowSummary] = useState(false);
	const [entity, setEntity] = useState("");
	const [stage, setStage] = useState("");
	const [wisdom, setWisdom] = useState("");
	const [courage, setCourage] = useState("");
	const [humanity, setHumanity] = useState("");
	const [justice, setJustice] = useState("");
	const [temp, setTemp] = useState("");
	const [transcend, setTranscend] = useState("");
	const [vision, setVision] = useState("");
	const [achieveVision, setAchieveVision] = useState("");
	const businessToolData = useSelector(
		(state) => state.business.businessToolData,
	);

	const GreenRadio = withStyles({
		root: {
			color: "#C2C9D1",
			"&$checked": {
				color: "#05B452",
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const handleSubmit = () => {
		if (name.length > 0) {
			// TODO SEND TO THE API
			setShowSummary(true);
		} else return;
	};

	const handleData = () => {
		const visionData = {
			vision: {
				name: name,
				description: description,
				entity: entity,
				stage: stage,
				wisdom: wisdom,
				courage: courage,
				humanity: humanity,
				justice: justice,
				temp: temp,
				transcend: transcend,
				vision: vision,
				achieveVision: achieveVision,
			},
		};
		dispatch(setBusinessToolData({ ...businessToolData, ...visionData }));
	};

	const handleContinue = () => {
		handleData();
		dispatch(setProgress(1));
		setShowSummary(false);
		navigate("/business");
	};

	const handleEntities = (event) => {
		if (entity?.length === 1) {
			setEntity("");
		} else {
			setEntity(event.target.name);
		}
	};

	const handleStage = (event) => {
		if (stage?.length === 1) {
			setStage("");
		} else {
			setStage(event.target.name);
		}
	};

	const handleWisdom = (event) => {
		if (wisdom?.length === 1) {
			setWisdom("");
		} else {
			setWisdom(event.target.name);
		}
	};

	const handleCourage = (event) => {
		if (courage?.length === 1) {
			setCourage("");
		} else {
			setCourage(event.target.name);
		}
	};

	const handleHumanity = (event) => {
		if (humanity?.length === 1) {
			setHumanity("");
		} else {
			setHumanity(event.target.name);
		}
	};

	const handleJustice = (event) => {
		if (justice?.length === 1) {
			setJustice("");
		} else {
			setJustice(event.target.name);
		}
	};

	const handleTemp = (event) => {
		if (temp?.length === 1) {
			setTemp("");
		} else {
			setTemp(event.target.name);
		}
	};

	const handleTranscend = (event) => {
		if (transcend?.length === 1) {
			setTranscend("");
		} else {
			setTranscend(event.target.name);
		}
	};

	return (
		<Flex vertical style={{ width: "100%" }}>
			<Flex className={{ textAlign: "left", paddingBottom: 40 }}>
				<TitleText
					text={
						!showSummary
							? "Vision, Mission, Values"
							: `Here are ${name}'s vision, mission and values!`
					}
				/>
			</Flex>
			<hr className={styles.sectionLine} />
			<Flex style={{ height: 220 }} horizontal align="center" justify="center">
				<img width={58} height={152} src={RedHair} />
				<BodyText text="Clarify what you want your business to do and why." />
			</Flex>

			{!showSummary ? (
				<>
					<FormInput
						isRequired={true}
						name="monthly-cost"
						placeholder="What's the name of your business? (required)"
						value={name}
						onChange={(e) => setName(e.target.value)}
						hasLabel
						formLabel="Business Name"
					/>

					<div className={styles.formSpacing} />

					<Flex vertical align="flex-start">
						<FormInput
							isRequired={true}
							isMultiline={true}
							name="monthly-cost"
							placeholder="Describe your business...."
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							hasLabel
							formLabel="Business Description"
						/>
					</Flex>

					<div className={styles.formSpacing} />

					<FormGroup column>
						<Flex style={{ textAlign: "left" }}>
							<TitleText text="What kind of legal entity is your business?" />
						</Flex>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{legalEntities.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									sx={{ fontSize: 14 }}
									control={
										<GreenRadio
											name={o}
											onChange={handleEntities}
											checked={entity.includes(o)}
											disabled={entity?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>

					<div className={styles.formSpacing} />

					<FormGroup column>
						<Flex style={{ textAlign: "left" }}>
							<TitleText text="What stage of business growth are you in?" />
						</Flex>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{bizStages.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleStage}
											checked={stage.includes(o)}
											disabled={stage?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>

					<div className={styles.formSpacing} />

					<Flex style={{ textAlign: "left", paddingBottom: 20 }}>
						<TitleText text="Choose 5 Values from the lists below that are important to you." />
					</Flex>

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Wisdom and Knowledge" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{wisdomKnowledgeValues.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleWisdom}
											checked={wisdom.includes(o)}
											disabled={wisdom?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>
					<div className={styles.checkboxSpacing} />

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Courage" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{courageValues.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleCourage}
											checked={courage.includes(o)}
											disabled={courage?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>
					<div className={styles.checkboxSpacing} />

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Humanity" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{humanityValues.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleHumanity}
											checked={humanity.includes(o)}
											disabled={humanity?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>
					<div className={styles.checkboxSpacing} />

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Justice" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{justiceValues.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleJustice}
											checked={justice.includes(o)}
											disabled={justice?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>
					<div className={styles.checkboxSpacing} />

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Temperance" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{temperanceValues.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleTemp}
											checked={temp.includes(o)}
											disabled={temp?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>
					<div className={styles.checkboxSpacing} />

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Transcendance" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{transcendenceValues.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleTranscend}
											checked={transcend.includes(o)}
											disabled={transcend?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>

					<div className={styles.formSpacing} />

					<Flex vertical align="flex-start">
						<FormInput
							isRequired={true}
							isMultiline={true}
							name="monthly-cost"
							placeholder="Your vision statement"
							value={vision}
							onChange={(e) => setVision(e.target.value)}
							hasLabel
							formLabel={`What's ${name}'s impact on the world, how does it improve
								customer's lives? In an ideal world, what does it stand for?`}
						/>
					</Flex>

					<div className={styles.formSpacing} />

					<Flex vertical align="flex-start">
						<FormInput
							isRequired={true}
							isMultiline={true}
							name="monthly-cost"
							placeholder={`What does ${name} need to do to achieve its vision?`}
							value={achieveVision}
							onChange={(e) => setAchieveVision(e.target.value)}
							hasLabel
							formLabel={`What will ${name} do to achieve its vision?`}
						/>
					</Flex>

					<div className={styles.formSpacing} />

					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleSubmit} />
					</Flex>
				</>
			) : (
				<>
					<Flex vertical align="center">
						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<TitleText text="Overview" />
							<div className={styles.checkboxSpacing} />
							<SubheaderText isHeavy text="Business Name" />
							<BodyText text={name} />
							<div className={styles.checkboxSpacing} />
							<SubheaderText isHeavy text="Business Description" />
							<BodyText text={description} />
							<div className={styles.checkboxSpacing} />
							<SubheaderText isHeavy text="Legal Entity" />
							<BodyText text={entity} />
							<div className={styles.checkboxSpacing} />

							<SubheaderText isHeavy text="Growth Stage" />
							<BodyText text={stage} />
						</Flex>

						<div className={styles.formSpacing} />

						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<TitleText
								text={`Here are ${name}’s vision, mission and values!`}
							/>
							<div className={styles.checkboxSpacing} />
							<SubheaderText isHeavy text="Your Vision" />
							<BodyText text={vision} />
							<div className={styles.checkboxSpacing} />

							<SubheaderText isHeavy text="Shared Values" />
							<div className={styles.checkboxSpacing} />
							<BodyText text={`1. ${wisdom}`} />
							<BodyText text={`2. ${courage}`} />
							<BodyText text={`3. ${humanity}`} />
							<BodyText text={`4. ${justice}`} />
							<BodyText text={`5. ${temp}`} />
							<BodyText text={`6. ${transcend}`} />
						</Flex>

						<div className={styles.formSpacing} />
					</Flex>
					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleContinue} />
					</Flex>
				</>
			)}
		</Flex>
	);
};

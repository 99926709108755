import { useEffect } from "react";
import { Row, Col, Flex } from "antd";
import styles from "./ContactPage.module.css";
import NavDrawer from "../../components/Shared/NavDrawer/NavDrawer";
import linkedin from "../../assets/linkedin.svg";
import instagram from "../../assets/instagram.svg";
import facebook from "../../assets/facebook.svg";
import { logScreen } from "../../utils/analytics";

const ContactPage = () => {
  useEffect(() => {
    logScreen("CONTACT");
  }, []);

  return (
    <>
      <NavDrawer />
      <div style={{ marginLeft: "16rem", position: "relative", zIndex: 1 }}>
        <Row style={{ padding: "44px 32px 32px 56px", margin: "0" }}>
          <Col span={24} style={{ padding: "0", margin: "0" }}>
            <Flex vertical justify="flex-start" align="center" gap={24}>
              <Flex style={{ width: "100%" }} vertical>
                <p className={styles.title}>Contact Us</p>
                <p className={styles.title}>Let's Talk About Your Business</p>
                <p className={styles.subTitle}>Entrepreneurs Contact Form</p>
              </Flex>
            </Flex>
          </Col>
          <Flex style={{ width: "100%" }} horizontal>
            <Flex style={{ width: "100%" }} vertical>
              <p className={styles.ccpName}>Centro Community Partners</p>
              <p className={styles.infoText}>825 Washington Suite #229</p>
              <p className={styles.infoText}>Oakland, CA 94607</p>
            </Flex>
            <Flex style={{ width: "100%" }} vertical>
              <p className={styles.infoText}>Phone: +1 (510) 250-3233</p>
              <p className={styles.infoText}>To Text: +1 (510) 250-3233</p>
              <p className={styles.infoText}>Email: info@centrocommunity.org</p>
            </Flex>
          </Flex>
          <Flex
            style={{ width: "100%", alignSelf: "center", textAlign: "center" }}
            horizontal
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/centro-community-partners/"
            >
              <img src={linkedin} alt="LinkedIn button" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/centrocommunity/"
            >
              <img src={instagram} alt="Instagram button" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/CentroCommunityPartners"
            >
              <img src={facebook} alt="Facebook button" />
            </a>
          </Flex>
        </Row>
      </div>
    </>
  );
};

export default ContactPage;

import { createSlice } from "@reduxjs/toolkit";

export const businessSlice = createSlice({
  name: "business",
  initialState: {
    businessToolProgress: null,
    businessToolData: {},
  },
  reducers: {
    setProgress: (state, action) => {
      state.businessToolProgress = action.payload;
    },
    setBusinessToolData: (state, action) => {
      state.businessToolData = action.payload;
    },
  },
});

export const { setProgress, setBusinessToolData } = businessSlice.actions;

export default businessSlice.reducer;

import { useEffect } from "react";
import { Row, Col, Flex } from "antd";
import styles from "./BusinessSummary.module.css";
import NavDrawer from "../../components/Shared/NavDrawer/NavDrawer";
import { logScreen } from "../../utils/analytics";
import {
  VisionMissionValuesForm,
  PersonalFinanceForm,
  MarketAnalysisForm,
  ProductServicesForm,
  MarketingStrategyForm,
  OperationsForm,
  BusinessFinanceForm,
} from "./BusinessPlan/Forms";
import { useSelector } from "react-redux";
import Back from "../../assets/Back.png";
import { useNavigate } from "react-router-dom";
import HeadlineText from "../../components/Shared/Text/HeadlineText.jsx";

const BusinessSummaryFormPage = () => {
  const navigate = useNavigate();
  const progress = useSelector(
    (state) => state.business.businessToolProgress ?? 0,
  );

  useEffect(() => {
    logScreen("BUSINESS_SUMMARY_FORM");
  }, []);

  const renderForm = () => {
    switch (progress) {
      case 0:
        return <VisionMissionValuesForm />;
        break;
      case 1:
        return <PersonalFinanceForm />;
        break;
      case 2:
        return <MarketAnalysisForm />;
        break;
      case 3:
        return <ProductServicesForm />;
        break;
      case 4:
        return <MarketingStrategyForm />;
        break;
      case 5:
        return <OperationsForm />;
        break;
      case 6:
        return <BusinessFinanceForm />;
        break;
      default:
        0;
    }
  };

  const handleBack = () => {
    navigate("/business");
  };

  return (
    <>
      <NavDrawer />

      <div style={{ marginLeft: "16rem", position: "relative", zIndex: 1 }}>
        <Row style={{ padding: "40px 32px 32px 56px", margin: "0" }}>
          <Col span={24}>
            <Flex vertical justify="flex-start" align="center" gap={24}>
              <Flex
                style={{ width: "100%", paddingLeft: 40 }}
                justify="flex-start"
                vertical
              >
                <button className={styles.btnWrap} onClick={handleBack}>
                  <Flex align="center" horizontal>
                    <img
                      className={styles.backButton}
                      src={Back}
                      alt="Back Button"
                    />
                    <HeadlineText isHeavy text="Business Summary" />
                  </Flex>
                </button>
                <Flex vertical className={styles.container}>
                  {progress !== null ? renderForm() : <div />}
                </Flex>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BusinessSummaryFormPage;

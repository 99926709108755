import { useState } from "react";
import styles from "../../BusinessPlanningTool.module.css";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import Button from "../../../../components/Shared/Button/Button";
import FormInput from "../../../../components/Shared/FormInput/FormInput";
import {
	industryList,
	agList,
	diningEntList,
	domesticList,
	productsList,
	healthFitnessList,
	technicalServicesList,
	professionalList,
	otherList,
	customerActivityList,
	customerWorkList,
	customerEdList,
	competitorsList,
	volatileList,
	regulatedList,
	marketAgeList,
	growingList,
} from "../../../../utils/marketAnalysisData";
import {
	priceData,
	customerServiceData,
	qualityData,
	locationData,
} from "../../../../utils/productServiceData";
import { useDispatch, useSelector } from "react-redux";
import {
	setProgress,
	setBusinessToolData,
} from "../../../../redux/slices/businessSlice";
import LabCoat from "../../../../assets/BusinessPlanningTool/people_images/lab_coat.png";
import TitleText from "../../../../components/Shared/Text/TitleText.jsx";
import BodyText from "../../../../components/Shared/Text/BodyText.jsx";
import SubheaderText from "../../../../components/Shared/Text/SubheaderText.jsx";
import removeNonNumericalChars from "../../../../utils/removeNonNumericalChars.js";

export const MarketAnalysisForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showSummary, setShowSummary] = useState(false);
	const [industry, setIndustry] = useState("");
	const [subIndustry, setSubIndustry] = useState("");
	const [growing, setGrowing] = useState("");
	const [marketAge, setMarketAge] = useState("");
	const [regulated, setRegulated] = useState("");
	const [volatile, setVolatile] = useState("");
	const [competitor, setCompetitor] = useState("");
	const [youngCustomers, setYoungCustomers] = useState("");
	const [oldCustomers, setOldCustomers] = useState("");
	const [lowSalary, setLowSalary] = useState("");
	const [highSalary, setHighSalary] = useState("");
	const [customerEd, setCustomerEd] = useState([]);
	const [customerWork, setCustomerWork] = useState([]);
	const [customerActivity, setCustomerActivity] = useState([]);
	const [percentage, setPercentage] = useState({
		men: "",
		women: "",
		nonB: "",
	});
	const [distance, setDistance] = useState({
		walking: "",
		short: "",
		long: "",
		online: "",
	});
	const [prices, setPrices] = useState("");
	const [cs, setCs] = useState("");
	const [quality, setQuality] = useState("");
	const [location, setLocation] = useState("");
	const businessToolData = useSelector(
		(state) => state.business.businessToolData,
	);

	const GreenCheckbox = withStyles({
		root: {
			color: "#C2C9D1",
			"&$checked": {
				color: "#05B452",
			},
		},
		checked: {},
	})((props) => <Checkbox color="default" {...props} />);

	const GreenRadio = withStyles({
		root: {
			color: "#C2C9D1",
			"&$checked": {
				color: "#05B452",
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const handleSave = () => {
		// TODO SEND TO THE API
		setShowSummary(true);
	};

	const handleData = () => {
		const marketAnalysisData = {
			marketAnalysis: {
				industry: industry,
				subIndustry: subIndustry,
				growing: growing,
				marketAge: marketAge,
				regulated: regulated,
				volatile: volatile,
				competitor: competitor,
				youngCustomers: youngCustomers,
				oldCustomers: oldCustomers,
				lowSalary: lowSalary,
				highSalary: highSalary,
				customerEd: customerEd,
				customerWork: customerWork,
				customerActivity: customerActivity,
				percentage: percentage,
				distance: distance,
				prices: prices,
				cs: cs,
				quality: quality,
				location: location,
			},
		};
		dispatch(
			setBusinessToolData({ ...businessToolData, ...marketAnalysisData }),
		);
	};

	const handleContinue = () => {
		handleData();
		dispatch(setProgress(3));
		setShowSummary(false);
		navigate("/business");
	};

	const handleIndustry = (event) => {
		if (industry?.length === 1) {
			setIndustry("");
		} else {
			setIndustry(event.target.name);
		}
	};

	const handleSetSubIndustry = (event) => {
		if (subIndustry?.length === 1) {
			setSubIndustry("");
		} else {
			setSubIndustry(event.target.name);
		}
	};

	const handleSubIndustry = () => {
		switch (industry) {
			case "Agriculture":
				return agList;
				break;
			case "Dining and Entertainment":
				return diningEntList;
				break;
			case "Domestic Services":
				return domesticList;
				break;
			case "Products":
				return productsList;
				break;
			case "Health and Fitness":
				return healthFitnessList;
				break;
			case "Technical Services":
				return technicalServicesList;
				break;
			case "Professional Services":
				return professionalList;
				break;
			case "Other":
				return otherList;
				break;
			default:
				professionalList;
		}
	};

	const handleGrowing = (event) => {
		if (growing?.length === 1) {
			setGrowing("");
		} else {
			setGrowing(event.target.name);
		}
	};

	const handleMarketAge = (event) => {
		if (marketAge?.length === 1) {
			setMarketAge("");
		} else {
			setMarketAge(event.target.name);
		}
	};

	const handleRegulated = (event) => {
		if (regulated?.length === 1) {
			setRegulated("");
		} else {
			setRegulated(event.target.name);
		}
	};

	const handleVolatile = (event) => {
		if (volatile?.length === 1) {
			setVolatile("");
		} else {
			setVolatile(event.target.name);
		}
	};

	const handleCompetitor = (event) => {
		if (competitor?.length === 1) {
			setCompetitor("");
		} else {
			setCompetitor(event.target.name);
		}
	};

	const handleCustomerEd = (event) => {
		if (customerEd.includes(event.target.name)) {
			const name = event.target.name;
			const trim = customerEd.filter((item) => item !== name);
			setCustomerEd(trim);
		} else {
			setCustomerEd([...customerEd, event.target.name]);
		}
	};

	const handleCustomerWork = (event) => {
		if (customerWork.includes(event.target.name)) {
			const name = event.target.name;
			const trim = customerWork.filter((item) => item !== name);
			setCustomerWork(trim);
		} else {
			setCustomerWork([...customerWork, event.target.name]);
		}
	};

	const handleCustomerActivity = (event) => {
		if (customerActivity.includes(event.target.name)) {
			const name = event.target.name;
			const trim = customerActivity.filter((item) => item !== name);
			setCustomerActivity(trim);
		} else {
			setCustomerActivity([...customerActivity, event.target.name]);
		}
	};

	const showError =
		Number(percentage.men) +
			Number(percentage.women) +
			Number(percentage.nonB) ===
		100;

	const showDistanceError =
		Number(distance.walking) +
			Number(distance.short) +
			Number(distance.long) +
			Number(distance.online) ===
		100;

	const handlePrices = (event) => {
		if (prices?.length === 1) {
			setPrices("");
		} else {
			setPrices(event.target.name);
		}
	};

	const handleCS = (event) => {
		if (cs?.length === 1) {
			setCs("");
		} else {
			setCs(event.target.name);
		}
	};

	const handleQuality = (event) => {
		if (quality?.length === 1) {
			setQuality("");
		} else {
			setQuality(event.target.name);
		}
	};

	const handleLocation = (event) => {
		if (location?.length === 1) {
			setLocation("");
		} else {
			setLocation(event.target.name);
		}
	};

	return (
		<Flex vertical style={{ width: "100%" }}>
			<Flex className={{ textAlign: "left", paddingBottom: 40 }}>
				<TitleText text="Market Analysis" />
			</Flex>
			<hr className={styles.sectionLine} />
			<Flex style={{ height: 220 }} horizontal align="center" justify="center">
				<img width={58} height={152} src={LabCoat} />
				<BodyText
					text="Describe your customers and identify your competitors within your
					industry."
				/>
			</Flex>

			{!showSummary ? (
				<>
					<Flex vertical>
						<Flex justify="flex-start" align="flex-start" vertical>
							<Flex vertical>
								<Flex style={{ textAlign: "left" }}>
									<TitleText text="What industry is your business in ?" />
								</Flex>
								<FormControl column>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										name="radio-buttons-group"
									>
										{industryList.map((o) => (
											<FormControlLabel
												className={styles.appFormLabel}
												value={o}
												control={
													<GreenRadio
														name={o}
														onChange={handleIndustry}
														checked={industry.includes(o)}
														disabled={industry?.length === 1}
														key={o}
													/>
												}
												label={o}
											/>
										))}
									</RadioGroup>
								</FormControl>
								{industry.length > 0 && (
									<>
										<div className={styles.formSpacing} />
										<Flex style={{ textAlign: "left" }}>
											<TitleText text="What industry segment is your business in ?" />
										</Flex>
										<FormGroup column>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												name="radio-buttons-group"
											>
												{handleSubIndustry().map((o) => (
													<FormControlLabel
														className={styles.appFormLabel}
														control={
															<GreenRadio
																name={o}
																onChange={handleSetSubIndustry}
																checked={subIndustry.includes(o)}
																disabled={subIndustry?.length === 1}
																key={o}
															/>
														}
														label={o}
														value={o}
													/>
												))}
											</RadioGroup>
										</FormGroup>
									</>
								)}
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText text="Is your business industry growing?" />
								</Flex>
								<FormGroup column>
									{growingList.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleGrowing}
													checked={growing.includes(o)}
													disabled={growing?.length === 1}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText text="How old is your business's market?" />
								</Flex>
								<FormGroup column>
									{marketAgeList.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleMarketAge}
													checked={marketAge.includes(o)}
													disabled={marketAge?.length === 1}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText text="How regulated is your business's industry?" />
								</Flex>
								<FormGroup column>
									{regulatedList.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleRegulated}
													checked={regulated.includes(o)}
													disabled={regulated?.length === 1}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText text="Is your business's industry volatile?" />
								</Flex>
								<FormGroup column>
									{volatileList.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleVolatile}
													checked={volatile.includes(o)}
													disabled={volatile?.length === 1}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText text="How many competitors can your customers choose from?" />
								</Flex>
								<FormGroup column>
									{competitorsList.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleCompetitor}
													checked={competitor.includes(o)}
													disabled={competitor?.length === 1}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="What's the age range (young to old) of the people who buy your
									products or services?"
									/>
								</Flex>
								<Flex row align="center">
									<FormInput
										isRequired={true}
										name="youngCustomers"
										placeholder="0"
										value={youngCustomers}
										onChange={(e) =>
											setYoungCustomers(removeNonNumericalChars(e.target.value))
										}
										isBox={true}
									/>
									<BodyText text="to" />
									<FormInput
										isRequired={true}
										name="oldCustomers"
										placeholder="100"
										value={oldCustomers}
										onChange={(e) =>
											setOldCustomers(removeNonNumericalChars(e.target.value))
										}
										isBox={true}
									/>
								</Flex>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="What's the range (low to high) for how much your customers
									earn per year?"
									/>
								</Flex>
								<Flex row align="center">
									<FormInput
										isRequired={true}
										name="lowSalary"
										placeholder="$0"
										value={lowSalary}
										onChange={(e) =>
											setLowSalary(removeNonNumericalChars(e.target.value))
										}
										isBox={true}
									/>
									<BodyText text="to" />
									<FormInput
										isRequired={true}
										name="highSalary"
										placeholder="$1,000,000"
										value={highSalary}
										onChange={(e) =>
											setHighSalary(removeNonNumericalChars(e.target.value))
										}
										isBox={true}
									/>
								</Flex>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="How much education do your customers have? (select all that
									apply)"
									/>
								</Flex>
								<FormGroup column>
									{customerEdList.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleCustomerEd}
													checked={customerEd.includes(o)}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="What types of work do your customers do? (select all that
									apply)"
									/>
								</Flex>
								<FormGroup column>
									{customerWorkList.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleCustomerWork}
													checked={customerWork.includes(o)}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText text="What do your customers enjoy doing? (select all that apply)" />
								</Flex>
								<FormGroup column>
									{customerActivityList.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleCustomerActivity}
													checked={customerActivity.includes(o)}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.formSpacing} />
								{/*Note: the spacing is ugly but fast*/}
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="What gender are your customers? (allocate % for each gender to
									equal 100)"
									/>
								</Flex>
								<Flex row align="center">
									<SubheaderText text="Men&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
									<FormInput
										isRequired={true}
										name="%"
										placeholder="%"
										value={percentage.men}
										onChange={(e) =>
											setPercentage({
												...percentage,
												men: removeNonNumericalChars(e.target.value),
											})
										}
										isBox
									/>
								</Flex>
								<Flex row align="center">
									<SubheaderText text="Women&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
									<FormInput
										isRequired={true}
										name="%"
										placeholder="%"
										value={percentage.women}
										onChange={(e) =>
											setPercentage({
												...percentage,
												women: removeNonNumericalChars(e.target.value),
											})
										}
										isBox
									/>
								</Flex>
								<Flex row align="center">
									<SubheaderText text="Non-Binary" />
									<FormInput
										isRequired={true}
										name="%"
										placeholder="%"
										value={percentage.nonB}
										onChange={(e) =>
											setPercentage({
												...percentage,
												nonB: removeNonNumericalChars(e.target.value),
											})
										}
										isBox
									/>
								</Flex>
								<Flex row align="center">
									{!showError && (
										<p className={styles.redSummaryLabel}>
											Please ensure the total adds up to 100%
										</p>
									)}
								</Flex>
								<div className={styles.formSpacing} />
								{/*Note: the spacing is ugly but fast*/}
								<Flex style={{ textAlign: "left" }}>
									<TitleText
										text="	Where are most of your customers located? (allocate % for each
									distance to equal 100)"
									/>
								</Flex>
								<Flex row align="center">
									<SubheaderText text="Walking Distance" />
									<FormInput
										isRequired={true}
										name="%"
										placeholder="%"
										value={distance.walking}
										onChange={(e) =>
											setDistance({
												...distance,
												walking: removeNonNumericalChars(e.target.value),
											})
										}
										isBox
									/>
								</Flex>
								<Flex row align="center">
									<SubheaderText
										text="Short
										Ride&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
									/>
									<FormInput
										isRequired={true}
										name="%"
										placeholder="%"
										value={distance.short}
										onChange={(e) =>
											setDistance({
												...distance,
												short: removeNonNumericalChars(e.target.value),
											})
										}
										isBox
									/>
								</Flex>
								<Flex row align="center">
									<SubheaderText
										text="	Long
										Ride&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
									/>
									<FormInput
										isRequired={true}
										name="%"
										placeholder="%"
										value={distance.long}
										onChange={(e) =>
											setDistance({
												...distance,
												long: removeNonNumericalChars(e.target.value),
											})
										}
										isBox
									/>
								</Flex>
								<Flex row align="center">
									<SubheaderText text="Online&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />

									<FormInput
										isRequired={true}
										name="%"
										placeholder="%"
										value={distance.online}
										onChange={(e) =>
											setDistance({
												...distance,
												online: removeNonNumericalChars(e.target.value),
											})
										}
										isBox
									/>
								</Flex>
								<Flex row align="center">
									{!showDistanceError && (
										<p className={styles.redSummaryLabel}>
											Please ensure the total adds up to 100%
										</p>
									)}
								</Flex>
								<div className={styles.formSpacing} />
								<Flex style={{ textAlign: "left" }}>
									<TitleText text="Choose 1 factor in each of the 4 categories below that are important to your customers when deciding what to buy" />
								</Flex>
								<div className={styles.checkboxSpacing} />
								<FormGroup column style={{ textAlign: "left" }}>
									<SubheaderText isHeavy text="Prices" />
									{priceData.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handlePrices}
													checked={prices.includes(o)}
													disabled={prices?.length === 1}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.checkboxSpacing} />
								<FormGroup column style={{ textAlign: "left" }}>
									<SubheaderText isHeavy text="Customer Service" />
									{customerServiceData.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleCS}
													checked={cs.includes(o)}
													disabled={cs?.length === 1}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.checkboxSpacing} />
								<FormGroup column style={{ textAlign: "left" }}>
									<SubheaderText isHeavy text="Quality" />
									{qualityData.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleQuality}
													checked={quality.includes(o)}
													disabled={quality?.length === 1}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.checkboxSpacing} />
								<FormGroup column style={{ textAlign: "left" }}>
									<SubheaderText isHeavy text="Location" />
									{locationData.map((o) => (
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenCheckbox
													name={o}
													onChange={handleLocation}
													checked={location.includes(o)}
													disabled={location?.length === 1}
													key={o}
												/>
											}
											label={o}
										/>
									))}
								</FormGroup>
								<div className={styles.formSpacing} />
								<Flex justify="flex-start" horizontal>
									<div />
									<Button content={"Save and Continue"} onClick={handleSave} />
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</>
			) : (
				<>
					<Flex vertical align="center">
						<Flex style={{ textAlign: "left", paddingBottom: 10 }}>
							<TitleText text="Industry Analysis Overview" />
						</Flex>
						<Flex
							vertical
							justify="space-between"
							className={styles.analysisSummaryCard}
						>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Industry" />
								<BodyText text={industry} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Segment" />
								<BodyText text={subIndustry} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Market Growth" />
								<BodyText text={growing} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Market Age" />
								<BodyText text={marketAge} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Regulations" />
								<BodyText text={regulated} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Volatility" />
								<BodyText text={volatile} />
							</Flex>

							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Competition" />
								<BodyText text={competitor} />
							</Flex>
						</Flex>

						<div className={styles.formSpacing} />

						<Flex style={{ textAlign: "left", paddingBottom: 10 }}>
							<TitleText text="Customer Snapshot" />
						</Flex>
						<Flex vertical className={styles.analysisSummaryCard}>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Age Range" />
								<BodyText text={`${youngCustomers} - ${oldCustomers}`} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Salary Range" />
								<BodyText text={`${lowSalary} - ${highSalary}`} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Educational Range" />
								<Flex>
									{customerEd.map((item) => (
										<>
											<BodyText key={item} text={`${item},`} />
											&nbsp;
										</>
									))}
								</Flex>
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Professional Range" />
								<Flex>
									{customerWork.map((item) => (
										<>
											<BodyText key={item} text={`${item},`} />
											&nbsp;
										</>
									))}
								</Flex>
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Customer Activities" />
								<Flex>
									{customerActivity.map((item) => (
										<>
											<BodyText key={item} text={`${item},`} />
											&nbsp;
										</>
									))}
								</Flex>
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Walking Distance" />
								<BodyText text={`${distance.walking}%`} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Short Distance" />
								<BodyText text={`${distance.short}%`} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Long Distance" />
								<BodyText text={`${distance.long}%`} />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<Flex row align="center" justify="space-between">
								<SubheaderText isHeavy text="Online" />
								<BodyText text={`${distance.online}%`} />
							</Flex>
						</Flex>

						<div className={styles.formSpacing} />

						<Flex style={{ textAlign: "left", paddingBottom: 10 }}>
							<TitleText text="Customer Preferences" />
						</Flex>
						<Flex
							align="flex-start"
							vertical
							className={styles.analysisSummaryCard}
						>
							<BodyText text={prices} />
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<BodyText text={cs} />
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<BodyText text={quality} />
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<BodyText text={location} />
						</Flex>

						<div className={styles.formSpacing} />
					</Flex>
					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleContinue} />
					</Flex>
				</>
			)}
		</Flex>
	);
};

export const priceData = ["Adjustable", "Affordable", "Premium"];

export const customerServiceData = [
	"Available",
	"Committed",
	"Fast",
	"Friendly",
	"Honest",
	"Knowledgeable",
	"Professional",
	"Punctual",
	"Reliable",
	"Respectful",
];

export const qualityData = [
	"Consistent",
	"Customizable",
	"Durable",
	"Easy to Use",
	"Entertaining",
	"Fresh",
	"Handmade",
	"Healthy",
	"Innovative",
	"Local",
	"Practical",
	"Stylish",
	"Sustainable",
];

export const locationData = [
	"Accessible",
	"Clean",
	"Convenient",
	"Cozy",
	"Online",
	"Safe",
	"Spacious",
];

import { useEffect } from "react";
import styles from "./OverlayPage.module.css";
import Background from "../../assets/Welcome_Background_Image.png";
import { logScreen } from "../../utils/analytics";

export default function OverlayPage({ isUser, Component }) {
  useEffect(() => {
    logScreen("WELCOME");
  }, []);

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.panel} />
        <div className={styles.background}>
          <img src={Background} alt="Background" />
        </div>
      </div>
      <div className={styles.content}>
        <Component isUser={isUser} />
      </div>
    </div>
  );
}

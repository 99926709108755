import { useState } from "react";
import styles from "../../BusinessPlanningTool.module.css";
import { Flex } from "antd";
import Button from "../../../../components/Shared/Button/Button";
import FormInput from "../../../../components/Shared/FormInput/FormInput";
import { useNavigate } from "react-router-dom";
import {
	priceData,
	customerServiceData,
	qualityData,
	locationData,
} from "../../../../utils/productServiceData";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
	setProgress,
	setBusinessToolData,
} from "../../../../redux/slices/businessSlice";
import HoldingIpad from "../../../../assets/BusinessPlanningTool/people_images/holding_ipad.png";
import TitleText from "../../../../components/Shared/Text/TitleText.jsx";
import BodyText from "../../../../components/Shared/Text/BodyText.jsx";
import SubheaderText from "../../../../components/Shared/Text/SubheaderText.jsx";
import removeNonNumericalChars from "../../../../utils/removeNonNumericalChars.js";

export const ProductServicesForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [product1, setProduct1] = useState({
		product: "",
		price: "",
	});
	const [product2, setProduct2] = useState({
		product: "",
		price: "",
	});
	const [product3, setProduct3] = useState({
		product: "",
		price: "",
	});
	const [prices, setPrices] = useState("");
	const [cs, setCs] = useState("");
	const [quality, setQuality] = useState("");
	const [location, setLocation] = useState("");
	const [showSummary, setShowSummary] = useState(false);
	const businessToolData = useSelector(
		(state) => state.business.businessToolData,
	);

	const GreenRadio = withStyles({
		root: {
			color: "#C2C9D1",
			"&$checked": {
				color: "#05B452",
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const handleSubmit = () => {
		setShowSummary(true);
		// TODO SEND TO THE API
	};

	const handleData = () => {
		const productServicesData = {
			productServices: {
				product1: product1,
				product2: product2,
				product3: product3,
				prices: prices,
				cs: cs,
				quality: quality,
				location: location,
			},
		};
		dispatch(
			setBusinessToolData({ ...businessToolData, ...productServicesData }),
		);
	};

	const handleContinue = () => {
		handleData();
		dispatch(setProgress(4));
		setShowSummary(false);
		navigate("/business");
	};

	const handlePrices = (event) => {
		if (prices?.length === 1) {
			setPrices("");
		} else {
			setPrices(event.target.name);
		}
	};

	const handleCS = (event) => {
		if (cs?.length === 1) {
			setCs("");
		} else {
			setCs(event.target.name);
		}
	};

	const handleQuality = (event) => {
		if (quality?.length === 1) {
			setQuality("");
		} else {
			setQuality(event.target.name);
		}
	};

	const handleLocation = (event) => {
		if (location?.length === 1) {
			setLocation("");
		} else {
			setLocation(event.target.name);
		}
	};

	return (
		<Flex vertical style={{ width: "100%" }}>
			<Flex className={{ textAlign: "left", paddingBottom: 40 }}>
				<TitleText text="Products and Services" />
			</Flex>
			<hr className={styles.sectionLine} />
			<Flex style={{ height: 220 }} horizontal align="center" justify="center">
				<img width={58} height={152} src={HoldingIpad} />
				<BodyText
					text="Identify what your business sells and why customers would buy from
					you."
				/>
			</Flex>

			{!showSummary ? (
				<>
					<Flex vertical align="flex-start">
						<Flex style={{ textAlign: "left" }}>
							<TitleText text="What products and services does your business sell? (Limit 3)" />
						</Flex>
						<div className={styles.formSpacing} />

						<Flex vertical align="flex-start">
							<SubheaderText isHeavy text="1.)" />
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="product1"
								placeholder="Product/Service 1"
								value={product1.product}
								onChange={(e) =>
									setProduct1({ ...product1, product: e.target.value })
								}
							/>
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="cost1"
								placeholder="Product/Service 1 Price"
								value={product1.price}
								onChange={(e) =>
									setProduct1({
										...product1,
										price: removeNonNumericalChars(e.target.value),
									})
								}
							/>
						</Flex>

						<div className={styles.checkboxSpacing} />

						<Flex vertical align="flex-start">
							<SubheaderText isHeavy text="2.)" />
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="product2"
								placeholder="Product/Service 2"
								value={product2.product}
								onChange={(e) =>
									setProduct2({ ...product2, product: e.target.value })
								}
							/>
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="cost2"
								placeholder="Product/Service 2 Price"
								value={product2.price}
								onChange={(e) =>
									setProduct2({
										...product2,
										price: removeNonNumericalChars(e.target.value),
									})
								}
							/>
						</Flex>

						<div className={styles.checkboxSpacing} />

						<Flex vertical align="flex-start">
							<SubheaderText isHeavy text="3.)" />
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="product3"
								placeholder="Product/Service 3"
								value={product3.product}
								onChange={(e) =>
									setProduct3({ ...product3, product: e.target.value })
								}
							/>
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="cost3"
								placeholder="Product/Service 3 Price"
								value={product3.price}
								onChange={(e) =>
									setProduct3({
										...product3,
										price: removeNonNumericalChars(e.target.value),
									})
								}
							/>
						</Flex>
					</Flex>

					<div className={styles.formSpacing} />

					<Flex style={{ textAlign: "left" }}>
						<TitleText
							text="	Choose 5 benefits that customers get when they buy a product or
						service from you."
						/>
					</Flex>

					<div className={styles.checkboxSpacing} />

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Prices" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{priceData.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handlePrices}
											checked={prices.includes(o)}
											disabled={prices?.length === 1}
											key={o}
										/>
									}
									value={o}
									label={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>

					<div className={styles.checkboxSpacing} />

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Customer Service" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{customerServiceData.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleCS}
											checked={cs.includes(o)}
											disabled={cs?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>

					<div className={styles.checkboxSpacing} />

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Quality" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{qualityData.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleQuality}
											checked={quality.includes(o)}
											disabled={quality?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>

					<div className={styles.checkboxSpacing} />

					<FormGroup column style={{ textAlign: "left" }}>
						<SubheaderText isHeavy text="Location" />
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
						>
							{locationData.map((o) => (
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenRadio
											name={o}
											onChange={handleLocation}
											checked={location.includes(o)}
											disabled={location?.length === 1}
											key={o}
										/>
									}
									label={o}
									value={o}
								/>
							))}
						</RadioGroup>
					</FormGroup>

					<div className={styles.formSpacing} />

					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleSubmit} />
					</Flex>
				</>
			) : (
				<>
					<Flex vertical align="center">
						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<Flex style={{ textAlign: "left", paddingBottom: 10 }}>
								<TitleText text="Here are the products and services you sell:" />
							</Flex>
							<BodyText text={`- ${product1.product}  ${product1.price}`} />
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<BodyText text={`- ${product2.product}  ${product2.price}`} />
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<BodyText text={`- ${product3.product}  ${product3.price}`} />
							<div className={styles.formSpacing} />
						</Flex>
						<div className={styles.formSpacing} />
						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<Flex style={{ textAlign: "left", paddingBottom: 10 }}>
								<TitleText text="These areas are where you provide the most value to customers!" />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>
							<>
								<BodyText text={`${1}.  ${prices}`} />
								<div
									style={{
										marginBottom: 10,
										marginTop: 10,
									}}
								/>
								<BodyText text={`${2}.  ${cs}`} />
								<div
									style={{
										marginBottom: 10,
										marginTop: 10,
									}}
								/>
								<BodyText text={`${3}.  ${quality}`} />
								<div
									style={{
										marginBottom: 10,
										marginTop: 10,
									}}
								/>
								<BodyText text={`${4}.  ${location}`} />
								<div
									style={{
										marginBottom: 10,
										marginTop: 10,
									}}
								/>
							</>

							<div className={styles.formSpacing} />
						</Flex>

						<div className={styles.formSpacing} />
					</Flex>
					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleContinue} />
					</Flex>
				</>
			)}
		</Flex>
	);
};

import { IconButton, Typography } from "@mui/material";
import styles from "./NavDrawer.module.css";
import { Link } from "react-router-dom";

export const OpenIconButton = ({ icon, label, route }) => {
  return (
    <Link to={`/${route}`}>
      <IconButton key={label} className={styles.ButtonOpen}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <img src={icon} alt={label} className={styles.ImageOpen} />
          <Typography className={styles.font} style={{ color: "#41493A" }}>
            {label}
          </Typography>
        </div>
      </IconButton>
    </Link>
  );
};

export const ClosedIconButton = ({ icon, label, route }) => {
  return (
    <div style={{ width: "100%" }}>
      <Link to={`/${route}`}>
        <IconButton key={label} className={styles.ButtonClosed}>
          <img src={icon} alt={label} className={styles.ImageClosed} />
        </IconButton>
      </Link>
      <Typography
        style={{
          wordWrap: "break-word",
          fontWeight: "400",
          fontFamily: "Open Sans",
        }}
        maxWidth="100%"
        fontSize={12}
      >
        {label}
      </Typography>
    </div>
  );
};

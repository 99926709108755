import { useState } from "react";
import PropTypes from "prop-types";
import styles from "./WelcomeCard.module.css";
import { useNavigate } from "react-router-dom";
import CentroLogo from "../../../assets/Centro_Logo_Horizontal.svg?react";
import { Card, CardContent } from "@mui/material";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setUserObject } from "../../../redux/slices/userSlice";
import { setRefreshToken, setIsUser } from "../../../redux/slices/authSlice";
import { login, signUp } from "../../../utils/analytics";

/**
 * Interactive card for user sign-in/sign-up on login page.
 */
export default function WelcomeCard() {
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const isUser = useSelector((state) => state.auth.isUser);
  const dispatch = useDispatch();
  // Inputs
  const [isReturningUser, setIsReturningUser] = useState(isUser ?? false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  // Checkbox
  const [isRemember, setIsRemember] = useState(email ? true : false);
  const navigate = useNavigate();

  const handleSignIn = async () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user ?? {};
        const token = userCredential.user.stsTokenManager.refreshToken ?? "";
        dispatch(setUserObject(user));
        dispatch(setRefreshToken(token));
        dispatch(setIsUser("true"));
        navigate("/home");
        login("email_pw");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleSignUp = async () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user ?? {};
        const token = userCredential.user.stsTokenManager.refreshToken ?? "";
        dispatch(setUserObject(user));
        dispatch(setRefreshToken(token));
        dispatch(setIsUser("true"));
        navigate("/home");
        signUp("email_pw");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  // TODO TOGGLE THIS BASED ON WHEHTER API SAYS USER EXISTS
  const handleGoogleAccount = (name) => {
    if (name?.length > 0) {
      login("email_pw");
      navigate("/home");
    } else {
      signUp("email_pw");
      navigate("/home");
    }
  };

  const handleGoogle = async () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        dispatch(setRefreshToken(token));
        dispatch(setIsUser("true"));
        const user = result?.user?.reloadUserInfo ?? {};
        dispatch(setUserObject(user));
        handleGoogleAccount(user?.displayName);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <Card className={styles.card}>
      <CardContent className={styles["card-content"]}>
        <div className={styles["card-flex"]}>
          <div className={styles.logoStyle}>
            <CentroLogo />
          </div>
          <div className={isReturningUser ? styles.welcome : styles.register}>
            <div className={styles["content-wrapper"]}>
              <p className={styles.header}>
                {isReturningUser ? "Welcome back!" : "Create a new account"}
              </p>
              <div className={styles["input-container"]}>
                {!isReturningUser ? (
                  <Input
                    label="Name"
                    placeholder="Enter your name"
                    value={name}
                    setValue={(e) => setName(e.target.value)}
                  />
                ) : (
                  <></>
                )}
                <Input
                  label="Email"
                  placeholder="Enter your email"
                  value={email}
                  setValue={(e) => setEmail(e.target.value)}
                />
                <Input
                  label="Password"
                  placeholder="Enter your password"
                  value={password}
                  setValue={(e) => setPassword(e.target.value)}
                  isCanHide={true}
                />
              </div>
              {isReturningUser ? (
                <div className={styles["password-actions"]}>
                  <Checkbox
                    label="Remember me"
                    isChecked={isRemember}
                    setIsChecked={(e) => setIsRemember(e.target.checked)}
                  />
                  <a
                    className={styles.link}
                    href="#"
                    onClick={() => alert("Needs routing")}
                  >
                    Forgot password?
                  </a>
                </div>
              ) : (
                // TODO enforce this with fcn
                <ul className={styles["password-details"]}>
                  <li>include upper and lowercase letters</li>
                  <li>include at least 1 number and 1 symbol</li>
                  <li>be at least 12 characters long</li>
                </ul>
              )}
              <div className={styles["button-group"]}>
                <Button
                  onClick={isReturningUser ? handleSignIn : handleSignUp}
                  content={"Sign " + (isReturningUser ? "in" : "up")}
                />
                {/*        <div className={styles.divider}>
                  <span />
                  <p>or</p>
                  <span />
                </div>*/}
                <Button
                  onClick={handleGoogle}
                  content={
                    <>
                      <div
                        className={styles["gsi-material-button-state"]}
                      ></div>
                      <div
                        className={
                          styles["gsi-material-button-content-wrapper"]
                        }
                      >
                        <div className={styles["gsi-material-button-icon"]}>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            style={{ display: "block" }}
                          >
                            <path
                              fill="#EA4335"
                              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                            ></path>
                            <path
                              fill="#4285F4"
                              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                            ></path>
                            <path
                              fill="#FBBC05"
                              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                            ></path>
                            <path
                              fill="#34A853"
                              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                            ></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                          </svg>
                        </div>
                        <span
                          className={styles["gsi-material-button-contents"]}
                        >
                          Sign {isReturningUser ? "in" : "up"} with Google
                        </span>
                        <span style={{ display: "none" }}>
                          Sign {isReturningUser ? "in" : "up"} with Google
                        </span>
                      </div>
                    </>
                  }
                  className={styles["gsi-material-button"]}
                />
              </div>
              {!isReturningUser ? (
                <small className={styles.disclaimer}>
                  By signing up you agree with the Centro Community Partners
                  <a
                    className={styles.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.centrocommunity.org/terms-of-service"
                  >
                    {` Terms of Service`}
                  </a>
                  {` and `}
                  <a
                    className={styles.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.centrocommunity.org/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </small>
              ) : (
                <></>
              )}
              <small className={styles["route-link"]}>
                {isReturningUser ? "Don't" : "Already"} have an account?{" "}
                <a
                  className={styles.link}
                  href="#"
                  onClick={() => setIsReturningUser(!isReturningUser)}
                >
                  Sign {isReturningUser ? "up" : "in"}
                </a>
              </small>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

WelcomeCard.propTypes = {
  /**
   * Determines the status of the user’s registration.
   * If true, display “welcome back” content.
   */
  isUser: PropTypes.bool,
  /**
   * Determines if the login info was previously stored.
   * If stored, prefill email/password if previously saved and default checkbox to “true”.
   */
  login: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
};

WelcomeCard.defaultProps = {
  login: { email: "", password: "" },
};

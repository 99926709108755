// JourneyMap.jsx
import React from "react";
import styles from "./JourneyMap.module.css";

const JourneyMap = () => {
  const stages = [
    { title: "Overview", svgFile: "src/assets/journeyMap/Overview.svg" },
    {
      title: "Business Model",
      svgFile: "src/assets/journeyMap/Business_Model.svg",
    },
    {
      title: "Business Plan",
      svgFile: "src/assets/journeyMap/Business_Plan.svg",
    },
    {
      title: "Personal Finance",
      svgFile: "src/assets/journeyMap/Personal_Finance.svg",
    },
    { title: "Leadership", svgFile: "src/assets/journeyMap/Leadership.svg" },
    { title: "Courses", svgFile: "src/assets/journeyMap/Courses.svg" },
    { title: "Capital", svgFile: "src/assets/journeyMap/Capital.svg" },
  ];

  return (
    <div className={styles.greenRectangle}>
      <div className={`${styles.stageContainer} ${styles.center}`}>
        <svg width="60" height="105">
          <image href={stages[0].svgFile} width="60" height="105" />
        </svg>
        {/* <p>{stage.title}</p> */}
      </div>
      {
        // <div className={`${styles.stageContainer} ${styles.bottom}`}>
        //   <svg width="60" height="105">
        //     <image href={stages[1].svgFile} width="60" height="105" />
        //   </svg>
        //   {/* <p>{stage.title}</p> */}
        // </div>
      }
      <div className={`${styles.stageContainer} ${styles.center}`}>
        <svg width="60" height="105">
          <image href={stages[2].svgFile} width="60" height="105" />
        </svg>
        {/* <p>{stage.title}</p> */}
      </div>
      {
        // <div className={`${styles.stageContainer} ${styles.top}`}>
        //   <svg width="60" height="105">
        //     <image href={stages[3].svgFile} width="60" height="105" />
        //   </svg>
        //   {/* <p>{stage.title}</p> */}
        // </div>
        // <div className={`${styles.stageContainer} ${styles.center}`}>
        //   <svg width="60" height="105">
        //     <image href={stages[4].svgFile} width="60" height="105" />
        //   </svg>
        //   {/* <p>{stage.title}</p> */}
        // </div>
        // <div className={`${styles.stageContainer} ${styles.bottom}`}>
        //   <svg width="60" height="105">
        //     <image href={stages[5].svgFile} width="60" height="105" />
        //   </svg>
        //   {/* <p>{stage.title}</p> */}
        // </div>
      }
      <div className={`${styles.stageContainer} ${styles.center}`}>
        <svg width="60" height="105">
          <image href={stages[6].svgFile} width="60" height="105" />
        </svg>
        {/* <p>{stage.title}</p> */}
      </div>
    </div>
  );
};

export default JourneyMap;

import ThreeCore from "../assets/logos/3Core.png";
import BigAustin from "../assets/logos/BigAustin2.png";
import CTAN from "../assets/logos/CTAN.png";
import Lendio from "../assets/logos/Lendio2.png";

export const lenderData = [
  {
    name: "BIGAUSTIN",
    description:
      "BIGAUSTIN focuses on a combination of small business and workforce development, providing innovative solutions and creating financial strength for our clients.",
    logo: BigAustin,
    url: "https://www.bigaustin.org/",
  },
  {
    name: "3CORE",
    description:
      "3Core offer Business working capital loan in an amount between $10,000 and $100,000, depending on each business’s individual needs.",
    logo: ThreeCore,
    url: "https://3coreedc.org/",
  },
  {
    name: "Lendio",
    description:
      "Lendio helps small businesses by connecting them with more lenders than anyone else in the world.",
    logo: Lendio,
    url: "https://www.lendio.com/",
  },
  {
    name: "Central Texas Angel Network (CTAN)",
    description:
      "Central Texas Angel Network (CTAN) assists entrepreneurs and early-stage growth companies by serving as a key source of funding, mentorship, strategic advice, and educational resources.",
    logo: CTAN,
    url: "https://ctan.com/",
  },
];

export const bizData = [
  {
    name: "BIGAUSTIN",
    description:
      "BIGAUSTIN focuses on a combination of small business and workforce development, providing innovative solutions and creating financial strength for our clients.",
    logo: BigAustin,
    url: "https://www.bigaustin.org/",
  },
  {
    name: "3CORE",
    description:
      "B3Core offer Business working capital loan in an amount between $10,000 and $100,000, depending on each business’s individual needs.",
    logo: ThreeCore,
    url: "https://3coreedc.org/",
  },
  {
    name: "Lendio",
    description:
      "Lendio helps small businesses by connecting them with more lenders than anyone else in the world.",
    logo: Lendio,
    url: "https://www.lendio.com/",
  },
  {
    name: "Central Texas Angel Network (CTAN)",
    description:
      "Central Texas Angel Network (CTAN) assists entrepreneurs and early-stage growth companies by serving as a key source of funding, mentorship, strategic advice, and educational resources.",
    logo: CTAN,
    url: "https://ctan.com/",
  },
];

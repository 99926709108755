import { useNavigate } from "react-router-dom";
import "../../../components/Shared/CourseProgressComponent/CourseProgress.css";
import { Flex } from "antd";
import "../../../components/Shared/CourseProgressComponent/CourseProgressItem.css";
import checkMark from "../../../assets/BusinessPlanningTool/checkmark.svg";
import chevron from "../../../assets/BusinessPlanningTool/chevron_right.svg";
import lock from "../../../assets/BusinessPlanningTool/lock.svg";

const BusinessCourseProgress = ({ items, progress, setState }) => {
  const navigate = useNavigate();

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (i < progress) {
      item.completed = true;
      item.current = false;
    } else if (i === progress) {
      item.completed = false;
      item.current = true;
    } else {
      item.completed = false;
      item.current = false;
    }
    items[i] = item;
  }

  const handleClick = (completed, current) => {
    if (!completed && current) {
      navigate("/business-tool");
    }
  };

  const BusinessCourseProgressItem = ({
    title,
    description,
    img_url,
    completed,
    current,
    target_url,
  }) => {
    const itemClass = completed
      ? "course-progress-item"
      : current
      ? "course-progress-item current"
      : "course-progress-item to-do";

    return (
      <div
        className={itemClass}
        onClick={() => handleClick(completed, current)}
      >
        <div className="divided">
          {img_url && (
            <img
              src={
                completed
                  ? checkMark
                  : img_url
              }
              alt="course progress item"
            />
          )}
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
        <img
          src={
            completed || current
              ? chevron
              : lock
          }
          className="end-icon"
          alt="arrow"
        />
      </div>
    );
  };

  return (
    <Flex gap={16} vertical style={{ paddingTop: "20px" }}>
      {items.map((item, index) => (
        <BusinessCourseProgressItem
          key={`${index + 1}-${item.title}`}
          title={`${index + 1}. ` + item.title}
          description={item.description}
          img_url={item.img_url}
          completed={item.completed}
          current={item.current}
          target_url={item.target_url}
          setState={setState}
        />
      ))}
    </Flex>
  );
};

export default BusinessCourseProgress;

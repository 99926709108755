export const customerMarketingChannels = [
	"Blog",
	"Events and Pop-Ups",
	"Farmer's Markets",
	"Family and Friends",
	"Internet Searches",
	"Social Media",
	"Website",
];

export const marketingChannels = [
	"Blog",
	"Distribution Companies",
	"Events and Pop-Ups",
	"Farmer's Markets",
	"Family and Friends",
	"Flyers and Brochures",
	"Home Delivery",
	"Internet Searches",
	"Newspaper/Magazine Ads",
	"Professional Referrals",
	"Social Media",
	"Store Front",
	"Street Advertising",
	"Website",
	"Wholesale",
];

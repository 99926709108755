import styles from "./CapitalOptions.module.css";
import { Option } from "./Option";
import { Flex } from "antd";
import { lenderData, bizData } from "../../utils/capitalData";

const CapitalOptions = () => {
  return (
    <Flex vertical align="flex-start" className={styles.container}>
      {/*<h2 className={styles.subtitle}>Funding Options</h2>*/}
      <Flex horizontal align="flex-start" className={styles.options}>
        {lenderData.map((o, index) => (
          <Option
            name={o.name}
            description={o.description}
            url={o.url}
            img={o.logo}
            key={`${index}-${o}`}
          />
        ))}
      </Flex>

      {/*      <h2 className={styles.subtitle}>Grant Options</h2>
      <Flex horizontal align="flex-start" className={styles.options}>
        {bizData.map((o, index) => (
          <Option
            name={o.name}
            description={o.description}
            url={o.url}
            img={o.logo}
            key={index}
          />
        ))}
      </Flex>*/}
    </Flex>
  );
};

export default CapitalOptions;

import PropTypes from "prop-types";
import "./CourseProgress.css";
import CourseProgressItem from "./CourseProgressItem";
import { Flex } from "antd";

const CourseProgress = ({ items, progress, setState }) => {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (i < progress) {
      item.completed = true;
      item.current = false;
    } else if (i === progress) {
      item.completed = false;
      item.current = true;
    } else {
      item.completed = false;
      item.current = false;
    }
    items[i] = item;
  }

  return (
    <Flex gap={16} vertical style={{ paddingTop: "20px" }}>
      {items.map((item, index) => (
        <CourseProgressItem
          key={item.title}
          title={`${index + 1}. ` + item.title}
          description={item.description}
          img_url={item.img_url}
          completed={item.completed}
          current={item.current}
          target_url={item.target_url}
          setState={setState}
        />
      ))}
    </Flex>
  );
};

CourseProgress.propTypes = {
  items: PropTypes.array.isRequired,
  progress: PropTypes.number.isRequired,
};

export default CourseProgress;

import CourseProgress from "../../components/Shared/CourseProgressComponent/CourseProgress";
import styles from "./BusinessPlanningTool.module.css";
import { Divider } from "antd";

const missionVisionValues = [
  {
    title: "Your Business",
    description:
      "In this activity you will provide the name of your business, along with a short description of it. ",
    img_url: "",
    target_url: "step1",
  },
  {
    title: "Personal Values",
    description:
      "This activity will help you identify your personal values, which is the first step in helping you create a business you are passionate about.",
    img_url: "",
    target_url: "step1",
  },
  {
    title: "Personal Values",
    description:
      "This activity will help you identify your personal values, which is the first step in helping you create a business you are passionate about.",
    img_url: "",
    target_url: "step1",
  },
  {
    title: "Personal Values",
    description:
      "This activity will help you identify your personal values, which is the first step in helping you create a business you are passionate about.",
    img_url: "",
    target_url: "step1",
  },
  {
    title: "Personal Values",
    description:
      "This activity will help you identify your personal values, which is the first step in helping you create a business you are passionate about.",
    img_url: "",
    target_url: "step1",
  },
  {
    title: "Personal Values",
    description:
      "This activity will help you identify your personal values, which is the first step in helping you create a business you are passionate about.",
    img_url: "",
    target_url: "step1",
  },
];

const currentProgress = 0;

const VisionMissionValues = ({ setState }) => {
  return (
    <div className={styles.BusinessPlanningToolSection}>
      <div className={styles.BusinessPlanningToolCard}>
        <p style={{ fontSize: "22px", fontWeight: 400, lineHeight: "28px" }}>
          Vision, Mission, Values
        </p>
        <Divider style={{ margin: "0", padding: "0", background: "#9AA0A6" }} />
        <CourseProgress
          items={missionVisionValues}
          progress={currentProgress}
          setState={setState}
        />
      </div>
    </div>
  );
};

export default VisionMissionValues;

import * as React from "react";
import { styled } from "@mui/material/styles";
import { IconButton, Drawer } from "@mui/material";
import Menu_Closed from "../../../assets/Menu_Closed.svg";
import Menu_Open from "../../../assets/Menu_Open.svg";
import Centro_Logo_Horizontal from "../../../assets/Centro_Logo_Horizontal.svg";
import Centro_Logo_Naked from "../../../assets/Centro_Logo_Naked.svg";
import styles from "./NavDrawer.module.css";
import { OpenIconButton, ClosedIconButton } from "./Buttons";
import { Flex } from "antd";
import home from "../../../assets/home.svg";
import monetization_on from "../../../assets/monetization_on.svg";
import school from "../../../assets/school.svg";
import cases from "../../../assets/cases.svg";
import contactus_icon from "../../../assets/contactus_icon.svg";
import profile_icon from "../../../assets/profile_icon.svg";

const drawerWidth = "16rem";

/**
 * A mixin function that defines the styles for a closed navigation drawer.
 *
 * @param {Object} theme - The theme object provided by Material-UI.
 * @returns {Object} - The CSS styles for the closed navigation drawer.
 */
const openedMixin = (theme) => ({
  width: drawerWidth,
  overflowX: "hidden",
});

/**
 * A mixin function that defines the styles for a closed navigation drawer.
 *
 * @param {Object} theme - The theme object provided by Material-UI.
 * @returns {Object} - The CSS styles for the closed navigation drawer.
 */
const closedMixin = (theme) => ({
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 6rem)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 6rem)`,
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // width: "10rem",
  // backgroundColor: "var(--mui-palette-main-dark)",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  height: "100%",
  position: "fixed",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StyledFlex = {
  height: "100%",
  padding: "44px 16px 56px 16px",
  justifyContent: "space-between",
  backgroundColor: "var(--mui-main-light)",
};

const NavDrawer = ({ isCollapsed, Hidden }) => {
  const [open, setOpen] = React.useState(true);

  const handleDrawerChange = () => {
    setOpen(!open);
  };

  if (Hidden) {
    return null;
  }

  return (
    <div style={{ zIndex: 1000, position: "relative" }}>
      <StyledDrawer variant="permanent" open={open}>
        <Flex
          justifyContent="space-between"
          align="center"
          vertical
          style={StyledFlex}
        >
          {open ? (
            <Flex
              justify="space-between"
              align="center"
              style={{ width: "100%", paddingBottom: "32px" }}
            >
              <img
                src={Centro_Logo_Horizontal}
                alt="Centro Logo"
                className={styles.NavImageOpen}
              />
              {/* <IconButton onClick={handleDrawerChange} style={{}}>
                <img src={Menu_Open} alt="Menu Open" />
              </IconButton> */}
            </Flex>
          ) : (
            <Flex align="center" vertical justify="center" gap={4}>
              <img
                className={styles.NavImageClosed}
                src={Centro_Logo_Naked}
                alt="Centro Logo"
              />
              {/* <IconButton
                onClick={handleDrawerChange}
                style={{ height: "40px", width: "40px", padding: "8px" }}
              >
                <img src={Menu_Closed} alt="Menu Closed" />
              </IconButton> */}
            </Flex>
          )}
          {open ? (
            <Flex
              vertical
              align="center"
              justify="space-between"
              style={{ height: "75vh" }}
            >
              <Flex vertical align="center">
                <OpenIconButton icon={home} label="Home" route="home" />
                <OpenIconButton
                  icon={cases}
                  label="Business Plan"
                  route="business"
                />
                <OpenIconButton
                  icon={monetization_on}
                  label="Capital"
                  route="capital"
                />
              </Flex>
              <Flex vertical align="center" gap={5}>
                <OpenIconButton
                  icon={profile_icon}
                  label="Profile"
                  route="profile"
                />
                <OpenIconButton
                  icon={contactus_icon}
                  label="Contact Us"
                  route="contact"
                />
              </Flex>
            </Flex>
          ) : (
            <Flex
              vertical
              align="center"
              justify="space-between"
              style={{ height: "75vh" }}
            >
              <Flex vertical align="center" gap={12}>
                <ClosedIconButton icon={home} label="Home" route="home" />
                <ClosedIconButton
                  icon={school}
                  label={"Business\nPlan"}
                  route="business"
                />
                <ClosedIconButton
                  icon={monetization_on}
                  label="Capital"
                  route="capital"
                />
              </Flex>
              <Flex vertical align="center" gap={5}>
                <ClosedIconButton
                  icon={profile_icon}
                  label="Profile"
                  route="profile"
                />
                <ClosedIconButton
                  icon={contactus_icon}
                  label="Contact Us"
                  route="contact"
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      </StyledDrawer>
    </div>
  );
};

export default NavDrawer;

export const courseData = [
  {
    tag: "Tag 1",
    title: "Title 1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci justo, aliquet et ligula non, fringilla volutpat nunc.",
    total: "1 Courses",
    button: "program",
  },
  {
    tag: "Tag 2",
    title: "Title 2",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc orci justo, aliquet et ligula non, fringilla volutpat nunc.",
    total: "2 Courses",
    button: "program",
  },
];

import PropTypes from "prop-types";
import styles from "./TabButton.module.css";

/**
 * Interactive multi-purpose button.
 */
export default function TabButton({ onClick, content, selected }) {
  return (
    <button
      className={selected ? styles.SelectedSectionButton : styles.SectionButton}
      onClick={onClick}
    >
      {content}
    </button>
  );
}

TabButton.propTypes = {
  /**
   * Fires when the button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Renders button content.
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Optional classname.
   */
  className: PropTypes.string,
  selected: PropTypes.bool,
};

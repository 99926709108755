import { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Input.module.css";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

/**
 * Text input.
 */
export default function Input({
  label,
  placeholder,
  value,
  setValue,
  isCanHide,
}) {
  const [showText, setShowText] = useState(false);

  const handleClickShowText = () => setShowText((show) => !show);

  const handleMouseDownText = (e) => {
    e.preventDefault();
  };

  return isCanHide ? (
    <FormControl
      className={styles.wrapper}
      variant="outlined"
      sx={{
        "& label.Mui-focused": {
          color: "grey.surface",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "grey.main",
          },
          "&.Mui-focused fieldset": {
            borderColor: "grey.main",
          },
        },
      }}
    >
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <OutlinedInput
        id={label}
        value={value}
        onChange={setValue}
        placeholder={placeholder}
        type={showText ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              sx={{
                color: "grey.secondary",
              }}
              aria-label={`toggle ${label} visibility`}
              onClick={handleClickShowText}
              onMouseDown={handleMouseDownText}
              edge="end"
            >
              {showText ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  ) : (
    <TextField
      className={styles.wrapper}
      id={label}
      label={label}
      variant="outlined"
      placeholder={placeholder}
      value={value}
      onChange={setValue}
      sx={{
        "& label.Mui-focused": {
          color: "grey.surface",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "grey.main",
          },
          "&.Mui-focused fieldset": {
            borderColor: "grey.main",
          },
        },
      }}
    />
  );
}

Input.propTypes = {
  /**
   * Label for input.
   */
  label: PropTypes.string,
  /**
   * Optional placeholder for input.
   */
  placeholder: PropTypes.string,
  /**
   * Value of input string.
   */
  value: PropTypes.string,
  /**
   * Changes input value. Fires on keystroke.
   * Value can be retrieved via e.target.value.
   */
  setValue: PropTypes.func,
  /**
   * Determines if there is a “hide text” feature.
   * If true, toggle icon visibility.
   */
  isCanHide: PropTypes.bool,
};

Input.defaultProps = {
  isCanHide: false,
};

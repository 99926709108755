import PropTypes from "prop-types";
import styles from "./Checkbox.module.css"; // Import your local CSS module

import { Checkbox as _Checkbox, FormControlLabel } from "@mui/material";

/**
 * Interactive checkbox.
 */
export default function Checkbox({ label, isChecked, setIsChecked }) {
  return (
    <>
    <FormControlLabel
      style={{ textAlign: 'left', lineHeight: '0.5'}}
      control={
        <_Checkbox
          checked={isChecked}
          onChange={setIsChecked}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            color: "grey.secondary",
            "&.Mui-checked": {
              color: "grey.secondary",
            },
          }}
        />
      }
      label={<p style={{textAlign: 'left', fontSize: '14px'}} className={styles.customLabel}>{label}</p>}
    />
    </>
  );
}

Checkbox.propTypes = {
  /**
   * Checkbox label.
   */
  label: PropTypes.string,
  /**
   * Determines the checkbox value.
   */
  isChecked: PropTypes.bool,
  /**
   * Fired when the checkbox is toggled.
   * Value can be retrieved via e.target.checked.
   */
  setIsChecked: PropTypes.func,
};

import { Flex, Divider } from "antd";
import styles from "./ProfilePage.module.css";
import FormInput from "../../components/Shared/FormInput/FormInput";
import Button from "../../components/Shared/Button/Button";
import { genderList } from "../../utils/profileData";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Check } from "@mui/icons-material";

export const ProfileInformation = ({
  // editAvatar,
  editProfile,
  saveProfile,
  profileInfo,
  isEditing,
  handleSetGender,
  handleSetName,
  signOut,
}) => {
  return (
    <>
      <Flex style={{ width: "100%" }} vertical justify="center" align="center">
        <Flex
          justify="space-between"
          align="center"
          horizontal
          className={styles.ProfileCard}
          gap={24}
        >
          {/*profileInfo.imgUrl !== null ? (
              <image className={styles.profileImg} />
            ) : (
              <div className={styles.profileImgPlaceholder} />
            )*/}
          <Flex vertical>
            <p className={styles.profileInfo}>{profileInfo.name}</p>
            <p className={styles.profileInfo}>{profileInfo.gender}</p>
          </Flex>
          <Flex vertical>
            {/*<Button onClick={editAvatar}>Edit Avatar</Button>*/}
            <Button onClick={signOut} content="Sign Out" />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        vertical
        justify="center"
        align="center"
        className={styles.ProfileCard}
      >
        <Flex
          style={{ width: "100%" }}
          justify="space-between"
          align="center"
          horizontal
          gap={24}
        >
          <p className={styles.profileTitle}>Profile Info</p>
          <Button
            onClick={editProfile}
            content={isEditing ? "Cancel" : "Edit"}
          />
        </Flex>

        <Flex
          vertical
          style={{ width: "30%", marginBottom: isEditing ? 30 : 0 }}
        >
          <p className={styles.profileCategory}>Full Name</p>
          {isEditing ? (
            <FormInput
              name="Full Name"
              placeholder="Full Name"
              value={profileInfo.name}
              onChange={handleSetName}
            />
          ) : (
            <p className={styles.profileData}>{profileInfo.name}</p>
          )}
        </Flex>

        <Divider style={{ margin: "0", padding: "0", background: "#9AA0A6" }} />

        <Flex vertical style={{ width: "30%" }}>
          <p className={styles.profileCategory}>Gender</p>
          {isEditing ? (
            <Box sx={{ minWidth: 300 }}>
              <FormControl
                fullWidth
                sx={{
                  minWidth: 270,
                  borderRadius: 8,
                  "& label.Mui-focused": {
                    color: "#00000080",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#00000080",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#B4FED4",
                    },
                  },
                }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={profileInfo.gender}
                  onChange={handleSetGender}
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  {genderList.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        minWidth: 270,
                        "&:hover": {
                          backgroundColor: "#EEFFF4",
                        },
                      }}
                    >
                      <Flex horizontal align="center" justify="space-between">
                        {<p className={styles.appFormLabel}>{option.label}</p>}
                        <div style={{ paddingLeft: 20 }}>
                          {option.label === profileInfo.gender && (
                            <Check fontSize="60" sx={{ color: "#05B452" }} />
                          )}
                        </div>
                      </Flex>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <p className={styles.profileData}>{profileInfo.gender}</p>
          )}
        </Flex>
        <Flex style={{ marginTop: 40 }}>
          {isEditing && <Button onClick={saveProfile} content="Save changes" />}
        </Flex>
      </Flex>
    </>
  );
};

import { experimental_extendTheme as extendTheme } from "@mui/material";
import { defaultTheme } from "@mui/material-next";

export const theme = extendTheme(
  {
    components: {
      // for datepicker
      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            "&.Mui-selected": {
              backgroundColor: "#05B452",
              "&:focus, &:hover": {
                backgroundColor: "#05B452",
              },
            },
            ":hover": {
              backgroundColor: "#EEFFF4",
            },
          },
        },
      },
      // for dropdowns
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "white",
            },
            "&:hover&.Mui-selected": {
              backgroundColor: "#EEFFF4",
            },
          },
        },
      },
    },
      main: {
        //dark green
        dark: "#B0BF3C",
        //light green
        light: "#DFE5B1",
        //byzantium
        secondary: {
          main: "#7A306C",
        },
        accent: {
          dark: "#7A306C",
        },
      },
      
    
  },
  defaultTheme
);

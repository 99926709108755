import { useState } from "react";
import styles from "../../BusinessPlanningTool.module.css";
import { Flex } from "antd";
import Box from "@mui/material/Box";
import Button from "../../../../components/Shared/Button/Button";
import FormInput from "../../../../components/Shared/FormInput/FormInput";
import { useNavigate } from "react-router-dom";
import { edList, experienceList } from "../../../../utils/profileData";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
	setProgress,
	setBusinessToolData,
} from "../../../../redux/slices/businessSlice";
import TwoPeople from "../../../../assets/BusinessPlanningTool/people_images/2_people.png";
import { Check } from "@mui/icons-material";
import TitleText from "../../../../components/Shared/Text/TitleText.jsx";
import BodyText from "../../../../components/Shared/Text/BodyText.jsx";
import SubheaderText from "../../../../components/Shared/Text/SubheaderText.jsx";

export const OperationsForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [employee1, setEmployee1] = useState({
		name: "",
		education: "",
		experience: "",
		manager: false,
	});
	const [employee2, setEmployee2] = useState({
		name: "",
		education: "",
		experience: "",
		manager: false,
	});
	const [employee3, setEmployee3] = useState({
		name: "",
		education: "",
		experience: "",
		manager: false,
	});
	const [showSummary, setShowSummary] = useState(false);
	const [activity1, setActivity1] = useState({
		activity: "",
		responsible: "",
		good: null,
	});
	const [activity2, setActivity2] = useState({
		activity: "",
		responsible: "",
		good: null,
	});
	const [activity3, setActivity3] = useState({
		activity: "",
		responsible: "",
		good: null,
	});
	const [partner1, setPartner1] = useState({
		type: "",
		name: "",
	});
	const [partner2, setPartner2] = useState({
		type: "",
		name: "",
	});
	const [partner3, setPartner3] = useState({
		type: "",
		name: "",
	});
	const businessToolData = useSelector(
		(state) => state.business.businessToolData,
	);

	const GreenCheckbox = withStyles({
		root: {
			color: "#C2C9D1",
			"&$checked": {
				color: "#05B452",
			},
		},
		checked: {},
	})((props) => <Checkbox color="default" {...props} />);

	const GreenRadio = withStyles({
		root: {
			color: "#C2C9D1",
			"&$checked": {
				color: "#05B452",
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const handleSubmit = () => {
		setShowSummary(true);
		// TODO SEND TO THE API
	};

	const handleData = () => {
		const operationsData = {
			operations: {
				employee1: employee1,
				employee2: employee2,
				employee3: employee3,
				activity1: activity1,
				activity2: activity2,
				activity3: activity3,
				partner1: partner1,
				partner2: partner2,
				partner3: partner3,
			},
		};
		dispatch(setBusinessToolData({ ...businessToolData, ...operationsData }));
	};

	const handleContinue = () => {
		handleData();
		dispatch(setProgress(6));
		setShowSummary(false);
		navigate("/business");
	};

	const handleMgmtBool = (isManager) => {
		if (isManager) {
			return "Yes";
		} else {
			return "No";
		}
	};

	return (
		<Flex vertical style={{ width: "100%" }}>
			<Flex className={{ textAlign: "left", paddingBottom: 40 }}>
				<TitleText text="Operations" />
			</Flex>
			<hr className={styles.sectionLine} />
			<Flex style={{ height: 220 }} horizontal align="center" justify="center">
				<img
					width={83}
					height={127}
					src={TwoPeople}
					style={{ paddingRight: 20 }}
				/>
				<BodyText text="Describe what your business and team members do." />
			</Flex>

			{!showSummary ? (
				<>
					<Flex vertical align="flex-start">
						<Flex style={{ textAlign: "left" }}>
							<TitleText text="Who are the employees at your business? (Up to 3)" />
						</Flex>
						<div className={styles.formSpacing} />

						<Flex vertical align="flex-start">
							<SubheaderText isHeavy text="1.)" />
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="employee1"
								placeholder="Employee 1 Name"
								value={employee1.name}
								onChange={(e) =>
									setEmployee1({ ...employee1, name: e.target.value })
								}
							/>
							<div className={styles.checkboxSpacing} />
							<p className={styles.appDropdownLabel}>Education</p>
							<Box sx={{ minWidth: 400 }}>
								<FormControl
									fullWidth
									sx={{
										minWidth: 400,
										borderRadius: 8,
										"& label.Mui-focused": {
											color: "#00000080",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#00000080",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#B4FED4",
											},
										},
									}}
								>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={employee1.education}
										onChange={(e) =>
											setEmployee1({
												...employee1,
												education: e.target.value,
											})
										}
										sx={{
											borderRadius: 2,
										}}
									>
										{edList.map((option) => (
											<MenuItem
												sx={{
													minWidth: 400,
													"&:hover": {
														backgroundColor: "#EEFFF4",
													},
												}}
												key={option.value}
												value={option.value}
											>
												<Flex horizontal align="center" justify="space-between">
													{
														<p className={styles.appFormLabel}>
															{option.label}
														</p>
													}
													<div style={{ width: 20 }} />
													{option.label === employee1.education && (
														<Check fontSize="60" sx={{ color: "#05B452" }} />
													)}
												</Flex>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							<div className={styles.checkboxSpacing} />
							<p className={styles.appDropdownLabel}>
								Years of professional experience
							</p>
							<Box sx={{ minWidth: 400 }}>
								<FormControl
									fullWidth
									sx={{
										minWidth: 400,
										borderRadius: 8,
										"& label.Mui-focused": {
											color: "#00000080",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#00000080",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#B4FED4",
											},
										},
									}}
								>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={employee1.experience}
										onChange={(e) =>
											setEmployee1({
												...employee1,
												experience: e.target.value,
											})
										}
										sx={{
											borderRadius: 2,
										}}
									>
										{experienceList.map((option) => (
											<MenuItem
												sx={{
													minWidth: 400,
													"&:hover": {
														backgroundColor: "#EEFFF4",
													},
												}}
												key={option.value}
												value={option.value}
											>
												<Flex horizontal align="center" justify="space-between">
													{
														<p className={styles.appFormLabel}>
															{option.label}
														</p>
													}
													<div style={{ width: 180 }} />
													{option.label === employee1.experience && (
														<Check fontSize="60" sx={{ color: "#05B452" }} />
													)}
												</Flex>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							<div className={styles.checkboxSpacing} />
							<Flex horizontal align="center">
								<BodyText text="Are they a manager?" />
								<FormControlLabel
									className={styles.appFormLabel}
									control={
										<GreenCheckbox
											name="manager"
											onChange={(e) =>
												setEmployee1({
													...employee1,
													manager: !employee1.manager,
												})
											}
											checked={employee1.manager}
											style={{ paddingLeft: 20 }}
										/>
									}
								/>
							</Flex>
						</Flex>

						<div className={styles.checkboxSpacing} />

						<Flex vertical align="flex-start">
							<SubheaderText isHeavy text="2.)" />
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="employee2"
								placeholder="Employee 2 Name"
								value={employee2.name}
								onChange={(e) =>
									setEmployee2({ ...employee2, name: e.target.value })
								}
							/>
							<div className={styles.checkboxSpacing} />
							<p className={styles.appDropdownLabel}>Education</p>
							<Box sx={{ minWidth: 400 }}>
								<FormControl
									fullWidth
									sx={{
										minWidth: 400,
										borderRadius: 8,
										"& label.Mui-focused": {
											color: "#00000080",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#00000080",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#B4FED4",
											},
										},
									}}
								>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={employee2.education}
										onChange={(e) =>
											setEmployee2({
												...employee2,
												education: e.target.value,
											})
										}
										sx={{
											borderRadius: 2,
										}}
									>
										{edList.map((option) => (
											<MenuItem
												sx={{
													minWidth: 400,
													"&:hover": {
														backgroundColor: "#EEFFF4",
													},
												}}
												key={option.value}
												value={option.value}
											>
												<Flex horizontal align="center" justify="space-between">
													{
														<p className={styles.appFormLabel}>
															{option.label}
														</p>
													}
													<div style={{ width: 20 }} />
													{option.label === employee2.education && (
														<Check fontSize="60" sx={{ color: "#05B452" }} />
													)}
												</Flex>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							<div className={styles.checkboxSpacing} />
							<p className={styles.appDropdownLabel}>
								Years of professional experience
							</p>
							<Box sx={{ minWidth: 400 }}>
								<FormControl
									fullWidth
									sx={{
										minWidth: 400,
										borderRadius: 8,
										"& label.Mui-focused": {
											color: "#00000080",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#00000080",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#B4FED4",
											},
										},
									}}
								>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={employee2.experience}
										onChange={(e) =>
											setEmployee2({
												...employee2,
												experience: e.target.value,
											})
										}
										sx={{
											borderRadius: 2,
										}}
									>
										{experienceList.map((option) => (
											<MenuItem
												key={option.value}
												value={option.value}
												sx={{
													minWidth: 400,
													"&:hover": {
														backgroundColor: "#EEFFF4",
													},
												}}
											>
												<Flex horizontal align="center" justify="space-between">
													{
														<p className={styles.appFormLabel}>
															{option.label}
														</p>
													}
													<div style={{ width: 20 }} />
													{option.label === employee2.experience && (
														<Check fontSize="60" sx={{ color: "#05B452" }} />
													)}
												</Flex>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							<div className={styles.checkboxSpacing} />
							<Flex horizontal align="center">
								<BodyText text="Are they a manager?" />
								<FormControlLabel
									control={
										<GreenCheckbox
											name="manager"
											onChange={(e) =>
												setEmployee2({
													...employee2,
													manager: !employee2.manager,
												})
											}
											checked={employee2.manager}
											style={{ paddingLeft: 20 }}
										/>
									}
								/>
							</Flex>
						</Flex>

						<div className={styles.checkboxSpacing} />

						<Flex vertical align="flex-start">
							<SubheaderText isHeavy text="3.)" />
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="employee3"
								placeholder="Employee 3 Name"
								value={employee3.name}
								onChange={(e) =>
									setEmployee3({ ...employee3, name: e.target.value })
								}
							/>
							<div className={styles.checkboxSpacing} />
							<p className={styles.appDropdownLabel}>Education</p>
							<Box sx={{ minWidth: 400 }}>
								<FormControl
									fullWidth
									sx={{
										minWidth: 400,
										borderRadius: 8,
										"& label.Mui-focused": {
											color: "#00000080",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#00000080",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#B4FED4",
											},
										},
									}}
								>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={employee3.education}
										onChange={(e) =>
											setEmployee3({
												...employee3,
												education: e.target.value,
											})
										}
										sx={{
											borderRadius: 2,
										}}
									>
										{edList.map((option) => (
											<MenuItem
												key={option.value}
												value={option.value}
												sx={{
													minWidth: 400,
													"&:hover": {
														backgroundColor: "#EEFFF4",
													},
												}}
											>
												<Flex horizontal align="center" justify="space-between">
													{
														<p className={styles.appFormLabel}>
															{option.label}
														</p>
													}
													<div style={{ width: 180 }} />
													{option.label === employee3.education && (
														<Check fontSize="60" sx={{ color: "#05B452" }} />
													)}
												</Flex>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							<div className={styles.checkboxSpacing} />
							<p className={styles.appDropdownLabel}>
								Years of professional experience
							</p>
							<Box sx={{ minWidth: 400 }}>
								<FormControl
									fullWidth
									sx={{
										minWidth: 400,
										borderRadius: 8,
										"& label.Mui-focused": {
											color: "#00000080",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#00000080",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#B4FED4",
											},
										},
									}}
								>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={employee3.experience}
										onChange={(e) =>
											setEmployee3({
												...employee3,
												experience: e.target.value,
											})
										}
										sx={{
											borderRadius: 2,
										}}
									>
										{experienceList.map((option) => (
											<MenuItem
												key={option.value}
												value={option.value}
												sx={{
													minWidth: 400,
													"&:hover": {
														backgroundColor: "#EEFFF4",
													},
												}}
											>
												<Flex horizontal align="center" justify="space-between">
													{
														<p className={styles.appFormLabel}>
															{option.label}
														</p>
													}
													<div style={{ width: 180 }} />
													{option.label === employee3.experience && (
														<Check fontSize="60" sx={{ color: "#05B452" }} />
													)}
												</Flex>{" "}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							<div className={styles.checkboxSpacing} />
							<Flex horizontal align="center">
								<BodyText text="Are they a manager?" />
								<FormControlLabel
									control={
										<GreenCheckbox
											name="manager"
											onChange={(e) =>
												setEmployee3({
													...employee3,
													manager: !employee3.manager,
												})
											}
											checked={employee3.manager}
											style={{ paddingLeft: 20 }}
										/>
									}
								/>
							</Flex>
						</Flex>

						<div className={styles.formSpacing} />

						<Flex style={{ textAlign: "left" }}>
							<TitleText text="What are your business's key activities? (Up to 3)" />
						</Flex>
						<div className={styles.formSpacing} />

						<Flex vertical align="flex-start">
							<SubheaderText isHeavy text="1.)" />
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="activity1"
								placeholder="What is the name of the activity?"
								value={activity1.activity}
								onChange={(e) =>
									setActivity1({ ...activity1, activity: e.target.value })
								}
							/>
							<div className={styles.checkboxSpacing} />
							<p className={styles.appDropdownLabel}>Who's responsible?</p>
							<Box sx={{ minWidth: 400 }}>
								<FormControl
									fullWidth
									sx={{
										minWidth: 400,
										borderRadius: 8,
										"& label.Mui-focused": {
											color: "#00000080",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#00000080",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#B4FED4",
											},
										},
									}}
								>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={activity1.responsible}
										onChange={(e) =>
											setActivity1({
												...activity1,
												responsible: e.target.value,
											})
										}
										sx={{
											borderRadius: 2,
										}}
									>
										{[
											{ value: employee1.name, label: employee1.name },
											{ value: employee2.name, label: employee2.name },
											{ value: employee3.name, label: employee3.name },
										].map((option) => (
											<MenuItem
												key={option.value}
												value={option.value}
												sx={{
													minWidth: 400,
													"&:hover": {
														backgroundColor: "#EEFFF4",
													},
												}}
											>
												<Flex horizontal align="center" justify="space-between">
													{
														<p className={styles.appFormLabel}>
															{option.label}
														</p>
													}
													<div style={{ width: 180 }} />
													{option.label === activity1.responsible && (
														<Check fontSize="60" sx={{ color: "#05B452" }} />
													)}
												</Flex>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</Flex>

						<div className={styles.checkboxSpacing} />

						<Flex vertical align="flex-start">
							<SubheaderText isHeavy text="2.)" />
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="activity2"
								placeholder="What is the name of the activity?"
								value={activity2.activity}
								onChange={(e) =>
									setActivity2({ ...activity2, activity: e.target.value })
								}
							/>
							<div className={styles.checkboxSpacing} />
							<p className={styles.appDropdownLabel}>Who's responsible?</p>
							<Box sx={{ minWidth: 400 }}>
								<FormControl
									fullWidth
									sx={{
										minWidth: 400,
										borderRadius: 8,
										"& label.Mui-focused": {
											color: "#00000080",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#00000080",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#B4FED4",
											},
										},
									}}
								>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={activity2.responsible}
										onChange={(e) =>
											setActivity2({
												...activity2,
												responsible: e.target.value,
											})
										}
										sx={{
											borderRadius: 2,
										}}
									>
										{[
											{ value: employee1.name, label: employee1.name },
											{ value: employee2.name, label: employee2.name },
											{ value: employee3.name, label: employee3.name },
										].map((option) => (
											<MenuItem
												sx={{
													minWidth: 400,
													"&:hover": {
														backgroundColor: "#EEFFF4",
													},
												}}
												key={option.value}
												value={option.value}
											>
												<Flex horizontal align="center" justify="space-between">
													{
														<p className={styles.appFormLabel}>
															{option.label}
														</p>
													}
													<div style={{ width: 180 }} />
													{option.label === activity2.responsible && (
														<Check fontSize="60" sx={{ color: "#05B452" }} />
													)}
												</Flex>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</Flex>

						<div className={styles.checkboxSpacing} />

						<Flex vertical align="flex-start">
							<SubheaderText isHeavy text="3.)" />
							<div className={styles.checkboxSpacing} />
							<FormInput
								isRequired={true}
								name="activity3"
								placeholder="What is the name of the activity?"
								value={activity3.activity}
								onChange={(e) =>
									setActivity3({ ...activity3, activity: e.target.value })
								}
							/>
							<div className={styles.checkboxSpacing} />
							<p className={styles.appDropdownLabel}>Who's responsible?</p>
							<Box sx={{ minWidth: 400 }}>
								<FormControl
									fullWidth
									sx={{
										minWidth: 400,
										borderRadius: 8,
										"& label.Mui-focused": {
											color: "#00000080",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderColor: "#00000080",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#B4FED4",
											},
										},
									}}
								>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={activity3.responsible}
										onChange={(e) =>
											setActivity3({
												...activity3,
												responsible: e.target.value,
											})
										}
										sx={{
											borderRadius: 2,
										}}
									>
										{[
											{ value: employee1.name, label: employee1.name },
											{ value: employee2.name, label: employee2.name },
											{ value: employee3.name, label: employee3.name },
										].map((option) => (
											<MenuItem
												sx={{
													minWidth: 300,
													"&:hover": {
														backgroundColor: "#EEFFF4",
													},
												}}
												key={option.value}
												value={option.value}
											>
												<Flex horizontal align="center" justify="space-between">
													{
														<p className={styles.appFormLabel}>
															{option.label}
														</p>
													}
													<div style={{ width: 180 }} />
													{option.label === activity3.responsible && (
														<Check fontSize="60" sx={{ color: "#05B452" }} />
													)}
												</Flex>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</Flex>

						<div className={styles.formSpacing} />
						<Flex style={{ textAlign: "left" }}>
							<TitleText text="Who are your key partners? (Up to 3)" />
						</Flex>
						<div className={styles.formSpacing} />
						<SubheaderText isHeavy text="1.)" />
						<div className={styles.checkboxSpacing} />
						<Flex vertical align="flex-start">
							<FormInput
								isRequired={true}
								name="monthly-cost"
								placeholder="Type of partnership"
								value={partner1.type}
								onChange={(e) =>
									setPartner1({ ...partner1, type: e.target.value })
								}
								hasLabel
								formLabel="What type of partner are they?"
							/>
						</Flex>
						<div className={styles.checkboxSpacing} />
						<Flex vertical align="flex-start">
							<FormInput
								isRequired={true}
								name="monthly-cost"
								placeholder="Partner name"
								value={partner1.name}
								onChange={(e) =>
									setPartner1({ ...partner1, name: e.target.value })
								}
								hasLabel
								formLabel="What is their name?"
							/>
						</Flex>
						<div className={styles.checkboxSpacing} />
						<SubheaderText isHeavy text="2.)" />
						<div className={styles.checkboxSpacing} />
						<Flex vertical align="flex-start">
							<FormInput
								isRequired={true}
								name="monthly-cost"
								placeholder="Type of partnership"
								value={partner2.type}
								onChange={(e) =>
									setPartner2({ ...partner2, type: e.target.value })
								}
								hasLabel
								formLabel="What type of partner are they?"
							/>
						</Flex>
						<div className={styles.checkboxSpacing} />
						<Flex vertical align="flex-start">
							<FormInput
								isRequired={true}
								name="monthly-cost"
								placeholder="Partner name"
								value={partner2.name}
								onChange={(e) =>
									setPartner2({ ...partner2, name: e.target.value })
								}
								hasLabel
								formLabel="What is their name?"
							/>
						</Flex>
						<div className={styles.checkboxSpacing} />
						<SubheaderText isHeavy text="3.)" />
						<div className={styles.checkboxSpacing} />
						<Flex vertical align="flex-start">
							<FormInput
								isRequired={true}
								name="monthly-cost"
								placeholder="Type of partnership"
								value={partner3.type}
								onChange={(e) =>
									setPartner3({ ...partner3, type: e.target.value })
								}
								hasLabel
								formLabel="What type of partner are they?"
							/>
						</Flex>
						<div className={styles.checkboxSpacing} />
						<Flex vertical align="flex-start">
							<FormInput
								isRequired={true}
								name="monthly-cost"
								placeholder="Partner name"
								value={partner3.name}
								onChange={(e) =>
									setPartner3({ ...partner3, name: e.target.value })
								}
								hasLabel
								formLabel="What is their name?"
							/>
						</Flex>
						<div className={styles.formSpacing} />
						<Flex style={{ textAlign: "left" }}>
							<TitleText text="Is your company good at each of the following?" />
						</Flex>
						<div className={styles.formSpacing} />

						<Flex horizontal align="center">
							{activity1?.activity?.length > 0 && (
								<>
									<SubheaderText text={activity1.activity} />
									<FormGroup row>
										<FormControlLabel
											style={{ paddingLeft: 20 }}
											className={styles.appFormLabel}
											control={
												<GreenRadio
													name={"Yes"}
													onChange={() =>
														setActivity1({ ...activity1, good: true })
													}
													checked={activity1.good === true}
												/>
											}
											label={"Yes"}
											value={"Yes"}
										/>
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenRadio
													name={"No"}
													onChange={() =>
														setActivity1({ ...activity1, good: false })
													}
													checked={
														activity1.good !== null && activity1.good === false
													}
												/>
											}
											label={"No"}
											value={"No"}
										/>
									</FormGroup>
								</>
							)}
						</Flex>
						<div className={styles.checkboxSpacing} />
						<Flex horizontal align="center">
							{activity2?.activity?.length > 0 && (
								<>
									<SubheaderText text={activity2.activity} />
									<FormGroup row>
										<FormControlLabel
											className={styles.appFormLabel}
											style={{ paddingLeft: 20 }}
											control={
												<GreenRadio
													name={"Yes"}
													onChange={() =>
														setActivity2({ ...activity2, good: true })
													}
													checked={activity2.good === true}
												/>
											}
											label={"Yes"}
											value={"Yes"}
										/>
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenRadio
													name={"No"}
													onChange={() =>
														setActivity2({ ...activity2, good: false })
													}
													checked={
														activity2.good !== null && activity2.good === false
													}
												/>
											}
											label={"No"}
											value={"No"}
										/>
									</FormGroup>
								</>
							)}
						</Flex>
						<div className={styles.checkboxSpacing} />
						<Flex horizontal align="center">
							{activity3?.activity?.length > 0 && (
								<>
									<SubheaderText text={activity3.activity} />
									<FormGroup row>
										<FormControlLabel
											className={styles.appFormLabel}
											style={{ paddingLeft: 20 }}
											control={
												<GreenRadio
													name={"Yes"}
													onChange={() =>
														setActivity3({ ...activity3, good: true })
													}
													checked={activity3.good === true}
												/>
											}
											label={"Yes"}
											value={"Yes"}
										/>
										<FormControlLabel
											className={styles.appFormLabel}
											control={
												<GreenRadio
													name={"No"}
													onChange={() =>
														setActivity3({ ...activity3, good: false })
													}
													checked={
														activity3.good !== null && activity3.good === false
													}
												/>
											}
											label={"No"}
											value={"No"}
										/>
									</FormGroup>
								</>
							)}
						</Flex>
					</Flex>

					<div className={styles.formSpacing} />

					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleSubmit} />
					</Flex>
				</>
			) : (
				<>
					<Flex vertical align="center">
						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<Flex style={{ textAlign: "left" }}>
								<TitleText text="Team List" />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>

							{employee1.name.length > 0 && (
								<Flex vertical align="flex-start">
									<SubheaderText isHeavy text={employee1.name} />
									<Flex row align="center" justify="flex-start">
										<BodyText text="Education Level: &nbsp;" />
										<BodyText isHeavy text={employee1.education} />
									</Flex>

									<Flex row align="center" justify="flex-start">
										<BodyText text="Professional Level: &nbsp;" />
										<BodyText isHeavy text={employee1.experience} />
									</Flex>

									<Flex row align="center" justify="flex-start">
										<BodyText text="Management Resonsibility: &nbsp;" />
										<BodyText
											isHeavy
											text={handleMgmtBool(employee1.manager)}
										/>
									</Flex>
								</Flex>
							)}
							<div className={styles.checkboxSpacing} />
							{employee2.name.length > 0 && (
								<Flex vertical align="flex-start">
									<SubheaderText isHeavy text={employee2.name} />
									<Flex row align="center" justify="flex-start">
										<BodyText text="Education Level: &nbsp;" />
										<BodyText isHeavy text={employee2.education} />
									</Flex>

									<Flex row align="center" justify="flex-start">
										<BodyText text="Professional Level: &nbsp;" />
										<BodyText isHeavy text={employee2.experience} />
									</Flex>

									<Flex row align="center" justify="flex-start">
										<BodyText text="Management Resonsibility: &nbsp;" />
										<BodyText
											isHeavy
											text={handleMgmtBool(employee2.manager)}
										/>
									</Flex>
								</Flex>
							)}
							<div className={styles.checkboxSpacing} />

							{employee3.name.length > 0 && (
								<Flex vertical align="flex-start">
									<SubheaderText isHeavy text={employee3.name} />
									<Flex row align="center" justify="flex-start">
										<BodyText text="Education Level: &nbsp;" />
										<BodyText isHeavy text={employee3.education} />
									</Flex>

									<Flex row align="center" justify="flex-start">
										<BodyText text="Professional Level: &nbsp;" />
										<BodyText isHeavy text={employee3.experience} />
									</Flex>

									<Flex row align="center" justify="flex-start">
										<BodyText text="Management Resonsibility: &nbsp;" />
										<BodyText
											isHeavy
											text={handleMgmtBool(employee3.manager)}
										/>
									</Flex>
								</Flex>
							)}
						</Flex>

						<div className={styles.formSpacing} />

						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<Flex style={{ textAlign: "left" }}>
								<TitleText text="Key Activities List" />
							</Flex>

							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>

							{activity1.activity.length > 0 && (
								<Flex row align="center" justify="flex-start">
									<BodyText text={activity1.activity} />
									<div
										style={{
											marginRight: 10,
											marginLeft: 10,
										}}
									/>
									<BodyText isHeavy text={activity1.responsible} />
								</Flex>
							)}
							{activity2.activity.length > 0 && (
								<Flex row align="center" justify="flex-start">
									<BodyText text={activity2.activity} />
									<div
										style={{
											marginRight: 10,
											marginLeft: 10,
										}}
									/>
									<BodyText isHeavy text={activity2.responsible} />
								</Flex>
							)}
							{activity3.activity.length > 0 && (
								<Flex row align="center" justify="flex-start">
									<BodyText text={activity3.activity} />
									<div
										style={{
											marginRight: 10,
											marginLeft: 10,
										}}
									/>
									<BodyText isHeavy text={activity3.responsible} />
								</Flex>
							)}
						</Flex>

						<div className={styles.formSpacing} />

						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<Flex style={{ textAlign: "left" }}>
								<TitleText text="Key Partners List" />
							</Flex>

							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>

							{partner1.name.length > 0 && (
								<Flex row align="center" justify="flex-start">
									<BodyText text={partner1.name} />
									<div
										style={{
											marginRight: 10,
											marginLeft: 10,
										}}
									/>
									<BodyText isHeavy text={partner1.type} />
								</Flex>
							)}
							{partner2.name.length > 0 && (
								<Flex row align="center" justify="flex-start">
									<BodyText text={partner2.name} />
									<div
										style={{
											marginRight: 10,
											marginLeft: 10,
										}}
									/>
									<BodyText isHeavy text={partner2.type} />
								</Flex>
							)}
							{partner3.name.length > 0 && (
								<Flex row align="center" justify="flex-start">
									<BodyText text={partner3.name} />
									<div
										style={{
											marginRight: 10,
											marginLeft: 10,
										}}
									/>
									<BodyText isHeavy text={partner3.type} />
								</Flex>
							)}
						</Flex>

						<div className={styles.formSpacing} />

						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<Flex style={{ textAlign: "left" }}>
								<TitleText text="Core Competencies" />
							</Flex>

							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>

							{activity1.good && <BodyText text={activity1.activity} />}
							{activity2.good && <BodyText text={activity2.activity} />}
							{activity3.good && <BodyText text={activity3.activity} />}
						</Flex>

						<div className={styles.formSpacing} />

						<Flex
							justify="flex-start"
							align="flex-start"
							vertical
							className={styles.marketingSummaryCard}
						>
							<Flex style={{ textAlign: "left" }}>
								<TitleText text="Development Areas" />
							</Flex>
							<div
								style={{
									marginBottom: 10,
									marginTop: 10,
								}}
							/>

							{!activity1.good && <BodyText text={activity1.activity} />}
							{!activity2.good && <BodyText text={activity2.activity} />}
							{!activity3.good && <BodyText text={activity3.activity} />}
						</Flex>

						<div className={styles.formSpacing} />
					</Flex>
					<Flex justify="flex-start" horizontal>
						<div />
						<Button content={"Save and Continue"} onClick={handleContinue} />
					</Flex>
				</>
			)}
		</Flex>
	);
};

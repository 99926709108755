export const genderList = [
	{
		value: "Female",
		label: "Female",
	},
	{
		value: "Male",
		label: "Male",
	},
	{
		value: "Non-Binary/Gender Fluid/Genderqueer",
		label: "Non-Binary/Gender Fluid/Genderqueer",
	},
	{
		value: "Transgender Man",
		label: "Transgender Man",
	},
	{
		value: "Transgender Woman",
		label: "Transgender Woman",
	},
	{
		value: "Other",
		label: "Other",
	},
	{
		value: "Prefer Not to Say",
		label: "Prefer Not to Say",
	},
];

export const raceList = [
	{
		value: "American Indian or Alaska Native",
		label: "American Indian or Alaska Native",
	},
	{
		value: "Asian",
		label: "Asian",
	},
	{
		value: "Black or African American",
		label: "Black or African American",
	},
	{
		value: "Native Hawaiian or Other Pacific Islander",
		label: "Native Hawaiian or Other Pacific Islander",
	},
	{
		value: "White",
		label: "White",
	},
	{
		value: "Multi-Racial",
		label: "Multi-Racial",
	},
	{
		value: "Middle Eastern",
		label: "Middle Eastern",
	},
	{
		value: "Prefer Not to Answer",
		label: "Prefer Not to Answer",
	},
	{
		value: "Other",
		label: "Other",
	},
];

export const multiRacialList = [
	{
		value: "American Indian or Alaska Native",
		label: "American Indian or Alaska Native",
	},
	{
		value: "Asian",
		label: "Asian",
	},
	{
		value: "Black or African American",
		label: "Black or African American",
	},
	{
		value: "Native Hawaiian or Other Pacific Islander",
		label: "Native Hawaiian or Other Pacific Islander",
	},
	{
		value: "White",
		label: "White",
	},
	{
		value: "Middle Eastern",
		label: "Middle Eastern",
	},
	{
		value: "Other",
		label: "Other",
	},
	{
		value: "Prefer Not to Answer",
		label: "Prefer Not to Answer",
	},
];

export const incomeList = [
	{
		value: "Less than $25,000",
		label: "Less than $25,000",
	},
	{
		value: "$25,000 to $49,999",
		label: "$25,000 to $49,999",
	},
	{
		value: "$50,000 to $74,999",
		label: "$50,000 to $74,999",
	},
	{
		value: "$75,000 to $99,999",
		label: "$75,000 to $99,999",
	},
	{
		value: "$100,000 to $124,000",
		label: "$100,000 to $124,000",
	},
	{
		value: "$125,000 to $149,999",
		label: "$125,000 to $149,999",
	},
	{
		value: "$150,000 and above",
		label: "$150,000 and above",
	},
	{
		value: "Prefer Not to Say",
		label: "Prefer Not to Say",
	},
];

export const countryList = [
	{
		value: "USA",
		label: "USA",
	},
];

export const edList = [
	{
		value: "Less Than High School",
		label: "Less Than High School",
	},
	{
		value: "High School Diploma or Equivalent (e.g., GED)",
		label: "High School Diploma or Equivalent (e.g., GED)",
	},
	{
		value: "Some College, But No Degree",
		label: "Some College, But No Degree",
	},
	{
		value: "Associate's Degree (e.g., AA, AS)",
		label: "Associate's Degree (e.g., AA, AS)",
	},
	{
		value: "Bachelor's Degree (e.g., BA, BS)",
		label: "Bachelor's Degree (e.g., BA, BS)",
	},
	{
		value: "Master's Degree (e.g., MA, MD, MBA)",
		label: "Master's Degree (e.g., MA, MD, MBA)",
	},
	{
		value: "Doctoral Degree (e.g., PhD, EdD, MD, JD)",
		label: "Doctoral Degree (e.g., PhD, EdD, MD, JD)",
	},
	{
		value: "Trade School",
		label: "Trade School",
	},
	{
		value: "Other",
		label: "Other",
	},
	{
		value: "Prefer Not to Say",
		label: "Prefer Not to Say",
	},
];

export const languageList = [
	{
		value: "English",
		label: "English",
	},
	{
		value: "Spanish",
		label: "Spanish",
	},
	{
		value: "French",
		label: "French",
	},
];

export const experienceList = [
	{
		value: "Less than 1 year",
		label: "Less than 1 year",
	},
	{
		value: "1-2 years",
		label: "1-2 years",
	},
	{
		value: "3-5 years",
		label: "3-5 years",
	},
	{
		value: "6-9 years",
		label: "6-9  years",
	},
	{
		value: "10+ years",
		label: "10+ years",
	},
];

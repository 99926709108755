export const legalEntities = [
	"Not established yet",
	"Sole Proprietorship",
	"Partnership",
	"Coop (worker owned cooperative)",
	"Limited Liability Company (LLC)",
	"S-Corp",
	"Other",
	"I don’t know",
];

export const bizStages = [
	"Concept (not started)",
	"Start-up (less than 2 years)",
	"Growth (2-5 years)",
	"Established (5+ years)",
];

export const wisdomKnowledgeValues = [
	"Creativity",
	"Curiosity",
	"Love of Learning",
	"Thoughtfulness",
	"Wisdom",
];

export const courageValues = [
	"Courage",
	"Bravery",
	"Enthusiasm",
	"Honesty",
	"Perserverance",
];

export const humanityValues = [
	"Humanity",
	"Emotional Intelligence",
	"Kindness",
	"Love ",
];

export const justiceValues = ["Justice", "Fairness", "Leadership", "Loyalty"];

export const temperanceValues = [
	"Temperance",
	"Forgiveness",
	"Humility",
	"Prudence",
	"Self-Discipline",
];

export const transcendenceValues = [
	"Transcendence",
	"Appreciation of Beauty & Excellence",
	"Gratitude",
	"Humor",
	"Optimism",
	"Spirituality",
];

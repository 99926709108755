import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom"; // Import useHistory
import "./CourseProgressItem.css";

const CourseProgressItem = ({
  title,
  description,
  img_url,
  completed,
  current,
  target_url,
  setState,
}) => {
  const navigate = useNavigate(); // Initialize useHistory

  const itemClass = completed
    ? "course-progress-item"
    : current
    ? "course-progress-item current"
    : "course-progress-item to-do";

  // Handle item click to navigate
  const handleClick = () => {
    if (!completed && current) {
      // Only navigate if the item is not completed and not current
      setState(target_url);
    }
  };

  return (
    <div className={itemClass} onClick={handleClick}>
      <div className="divided">
        {img_url && (
          <img
            src={
              completed
                ? "/src/assets/BusinessPlanningTool/checkmark.svg"
                : img_url
            }
            alt="course progress item"
          />
        )}
        <div>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
      <img
        src={
          completed || current
            ? "/src/assets/BusinessPlanningTool/chevron_right.svg"
            : "/src/assets/BusinessPlanningTool/lock.svg"
        }
        className="end-icon"
        alt="arrow"
      />
    </div>
  );
};

CourseProgressItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img_url: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  current: PropTypes.bool.isRequired,
  target_url: PropTypes.string.isRequired,
};

export default CourseProgressItem;

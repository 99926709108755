import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    value: 0,
    refreshToken: "",
    expirationTime: "",
    isUser: false,
  },
  reducers: {
    setRefreshToken(state, action) {
      state.refreshToken = action.payload;
      localStorage.setItem("refreshToken", action.payload);
    },
    setIsUser(state, action) {
      state.isUser = action.payload;
      localStorage.setItem("isUser", action.payload);
    },
  },
});

export const { setRefreshToken, setIsUser } = authSlice.actions;

export default authSlice.reducer;

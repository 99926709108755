import { useState, useEffect } from "react";
import { Row, Col, Flex } from "antd";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import styles from "./ProfilePage.module.css";
import NavDrawer from "../../components/Shared/NavDrawer/NavDrawer";
import { getAuth, signOut } from "firebase/auth";
import { setRefreshToken } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AccountInformation } from "./AccountInformation";
import { ProfileInformation } from "./ProfileInformation";
import { logScreen, logOut } from "../../utils/analytics";
import { setProfile } from "../../redux/slices/profileSlice";
import TabButton from "../../components/Shared/TabButton/TabButton";
import HeadlineText from "../../components/Shared/Text/HeadlineText.jsx";

// Note: no username, not requested in the Questionnaire -sic
// Note: the Questionnaire does not request address, just city -sic

export default function ProfilePage() {
  const auth = getAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.profile.profile);
  const [tab, setTab] = useState("profile");
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingAccount, setIsEditingAccount] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    gender: "",
    phone: "",
    dob: "",
    language: "",
    imgUrl: null,
  });

  useEffect(() => {
    setData(profileData);
  }, []);

  useEffect(() => {
    logScreen("PROFILE");
  }, []);

  const updateField = (fieldName, value) => {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSignOut = async () => {
    signOut(auth)
      .then(() => {
        logOut();
        dispatch(setRefreshToken(""));
        localStorage.removeItem("refreshToken");
        navigate("/*");
      })
      .catch((error) => {
        alert(error);
      });
  };

  // const handleEditAvatar = () => {
  //   alert("edit!");
  // };

  const handleEditProfile = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveProfile = () => {
    // TODO API stuff
    console.log("data::", data);
    setIsEditing(false);
    setIsEditingAccount(false);
    dispatch(setProfile(data));
  };

  const editName = (e) => {
    updateField("name", e.target.value);
  };

  const editGender = (e) => {
    updateField("gender", e.target.value);
  };

  const editEmail = (e) => {
    updateField("email", e.target.value);
  };

  const editPhone = (e) => {
    updateField("phone", e.target.value);
  };
  const editDob = (date) => {
    updateField("dob", date.$d);
  };

  const editLanguage = (e) => {
    updateField("language", e.target.value);
  };

  const handleEditAccountInfo = () => {
    setIsEditingAccount(!isEditingAccount);
  };

  const handleResetPw = () => {
    alert("reset!");
  };

  const tabs = {
    profile: (
      <ProfileInformation
        // editAvatar={handleEditAvatar}
        editProfile={handleEditProfile}
        saveProfile={handleSaveProfile}
        profileInfo={data}
        isEditing={isEditing}
        handleSetGender={editGender}
        handleSetName={editName}
        signOut={handleSignOut}
      />
    ),
    account: (
      <AccountInformation
        profileInfo={data}
        resetPassword={handleResetPw}
        editAccount={handleEditAccountInfo}
        isEditingAccount={isEditingAccount}
        handleSetEmail={editEmail}
        handleSetPhone={editPhone}
        handleSetDob={editDob}
        handleSetLanguage={editLanguage}
        handleSaveAccount={handleSaveProfile} // TODO should this be the same as above? -sic
      />
    ),
  };

  return (
    <>
      <NavDrawer />
      <div style={{ marginLeft: "16rem", position: "relative", zIndex: 1 }}>
        <Row style={{ padding: "32px 32px 56px" }}>
          <Col span={24}>
            <Flex vertical justify="flex-start" align="center" gap={24}>
              <Flex
                style={{ width: "100%", paddingLeft: 40 }}
                justify="flex-start"
                align="center"
              >
                <HeadlineText isHeavy text="Profile" />
              </Flex>

              <Flex
                horizontal
                align="center"
                justify="space-around"
                style={{ padding: "0", width: "100%" }}
              >
                <TabButton
                  onClick={() => {
                    setTab("profile");
                  }}
                  content="Profile Information"
                  selected={tab === "profile"}
                />
                <TabButton
                  onClick={() => {
                    setTab("account");
                  }}
                  content="Account Information"
                  selected={tab === "account"}
                />
              </Flex>
              {tabs[tab]}
            </Flex>
          </Col>
        </Row>
      </div>
    </>
  );
}

import BusinessCourseProgress from "./BusinessCourseProgress";
import styles from "../BusinessPlanningTool.module.css";
import { businessPlanData } from "../../../utils/businessPlanData";
import TitleText from "../../../components/Shared/Text/TitleText";

const BusinessPlanningToolSection = ({ setState, currentProgress }) => {
  return (
    <div className={styles.BusinessPlanningToolSection}>
      <div className={styles.BusinessPlanningToolCard}>
        <TitleText text="Steps to build your business plan" />
        <BusinessCourseProgress
          items={businessPlanData}
          progress={currentProgress}
          setState={setState}
        />
      </div>
    </div>
  );
};

export default BusinessPlanningToolSection;

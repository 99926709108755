import explore from "../assets/BusinessPlanningTool/explore.png";
import savings from "../assets/BusinessPlanningTool/savings.png"
import troubleshoot from "../assets/BusinessPlanningTool/troubleshoot.png";
import category from "../assets/BusinessPlanningTool/category.png"
import tactic from "../assets/BusinessPlanningTool/tactic.png";
import manufacturing from "../assets/BusinessPlanningTool/manufacturing.png"
import account_balance from "/src/assets/BusinessPlanningTool/account_balance.png"
export const businessPlanData = [
	{
		title: "Vision, Mission, Values",
		description: "Clarify what you want your business to do and why.",
		img_url: explore,
		target_url: "mission",
	},
	{
		title: "Personal Finance",
		description: "Understand your personal financial situation.",
		img_url: savings,
		target_url: "personal",
	},
	{
		title: "Market Analysis",
		description:
			"Identify what your business sells and why customers would buy from you.",
		img_url: troubleshoot,
		target_url: "market",
	},
	{
		title: "Products and Services",
		description:
			"Explore advanced patterns in React development, including context, higher-order components, and render props.",
		img_url: category,
		target_url: "products",
	},

	{
		title: "Marketing Strategy",
		description:
			"Describe how your business promotes and distributes its products/services.",
		img_url: tactic,
		target_url: "marketing",
	},
	{
		title: "Operations",
		description: "Describe what your business and team members do.",
		img_url: manufacturing,
		target_url: "operations",
	},
	{
		title: "Business Finance",
		description: "Understand the financial situation for your business.",
		img_url: account_balance,
		target_url: "finance",
	},
];
